.hint {
    @include font(copy-s);
    margin-bottom: px-to-rem(20px);
    line-height: 1.15;

    @include media-breakpoint-up(sm) {
        text-align: right;
    }

    &.help-hint {
        > a {
            display: block;
            position: relative;
            padding-right: px-to-rem(40px);
            text-align: right;
            text-decoration: none;
            cursor: pointer;

            @include media-breakpoint-down(sm) {
                @media (orientation: portrait) {
                    text-align: left;
                }
            }

            @include media-breakpoint-up(sm) {
                display: inline-block;
                text-align: left;
            }

            &::after {
                @extend [class^='icon-'];
                @extend .icon-info::before;
                position: absolute;
                top: 50%;
                right: 0;
                margin-top: px-to-rem(-15px);
                font-size: px-to-rem(30px);
            }
        }

        &.hint-overview {
            position: relative;
            right: px-to-rem(10px);
            padding: 0;
            margin: px-to-rem(25px) px-to-rem(10px) 0;

            @include media-breakpoint-up(xs) {
                position: absolute;
                margin: 0;
                bottom: .25em;
            }
        }
    }

    &.hidden {
        display: none;
    }
}

.hint-box {
    position: relative;
    width: 100%;
    padding: 10px 10px 10px 50px;
    border-radius: 10px;
    background-color: $color-deep-sky-blue;
    color: $color-white;
    text-align: left;

    &--clickable {
        cursor: pointer;
    }

    a {
        color: $color-white;
        text-decoration: underline;
    }

    &::before {
        @extend [class^='icon-'];
        @extend .icon-info::before;
        position: absolute;
        top: 10px;
        left: 10px;
        font-size: 30px;
    }
}

.ssl-hint {
    position: relative;
    margin-bottom: 0;
    padding: 18px 40px 18px 100px;
    background-color: $color-lighter-blue;

    &::after {
        @extend [class^='icon-'];
        @extend .icon-ssl-icon::before;
        display: inline-flex;
        position: absolute;
        top: 22px;
        left: 20px;
        align-items: center;
        justify-content: center;
        color: $color-green;
        font-size: px-to-rem(72px);
    }

    > a {
        text-decoration: underline;
    }
}
