.swiper-button-prev,
.swiper-button-next {
    outline: 0;
}

.fillup {
    height: 0;
    overflow: hidden;
}

.image-wrapper {
    position: relative;
    padding-bottom: (9px / 16px * 100%) - .1%;
    background-color: $color-white;

    > img {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        max-width: 100%;
        max-height: 100%;
        transform: translate(-50%, -50%);
    }
}

.cabin-type-image-gallery {
    .image-gallery-images {
        margin-bottom: 15px;
    }

    .swiper-button-prev,
    .swiper-button-next {
        width: 30px;
        height: 40px;
        margin-top: -.9em;
        padding-top: 9px;
        padding-left: 5px;
        background-color: $color-semi-white;
        font-size: 20px;

        // IE11
        @media all and (-ms-high-contrast: none) {
            display: none;
        }
    }

    .swiper-button-prev {
        &::before {
            @extend .icon-arrow-left::before;
        }
    }

    .swiper-button-next {
        &::before {
            @extend .icon-arrow-right::before;
        }
    }

    .enlarge-button {
        position: absolute;
        right: 9px;
        bottom: 8px;
        width: 36px;
        height: 36px;
        font-size: 18px;
        cursor: pointer;
        z-index: z-index(above);
    }

    .panorama {
        .button {
            position: absolute;
            right: 5px;
            bottom: 5px;
            height: 1.5rem;
            background-color: $color-sticky-cta-dark-blue;
            color: $color-white;
            font-size: .75em;
            font-weight: bold;

            &:hover {
                background-color: rgba($color-deep-sky-blue, .8);
            }
        }
    }

    .image-gallery-thumbs {
        // IE11
        @media all and (-ms-high-contrast: none) {
            display: none;
        }

        .image-wrapper {
            cursor: pointer;
        }

        .swiper-slide-thumb-active::after {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border: 2px solid $color-dark-blue;
            content: '';
        }
    }
}

// overlay
.swiper-overlay {
    margin: 0 -5px;

    .swiper {
        &::before,
        &::after {
            position: absolute;
            top: 50%;
            left: 0;
            width: 30px;
            height: 50px;
            margin-top: -25px;
            background-color: $color-semi-white;
            content: '';
            z-index: 2;

            @include media-breakpoint-up(sm) {
                width: 40px;
                height: 60px;
                margin-top: -30px;
            }
        }

        &::after {
            right: 0;
            left: unset;
        }
    }

    .swiper-button-prev,
    .swiper-button-next {
        font-size: 25px;

        @include media-breakpoint-up(sm) {
            font-size: 35px;
        }
    }

    .image-wrapper {
        margin: 0 0 10px 0;
    }

    .swiper-wrapper-thumbnail {
        .swiper {
            margin-top: 5px;

            &::before,
            &::after {
                display: none;
            }
        }

        .image-wrapper {
            margin: auto;
        }

        .swiper-slide-thumb-active::after {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border: 5px solid $color-semi-primary;
            content: '';
        }
    }
}

.cabin-panorama {
    .overlay {
        padding: 0;
        background-color: transparent;
    }
}

.overlay-cabin-panorama {
    @include media-breakpoint-down(xs) {
        width: 100%;
        height: calc(100vh - 20px);
    }

    .panorama-iframe {
        width: 100%;
        height: 70vh;

        @include media-breakpoint-down(xs) {
            height: 100%;
        }
    }
}
