.sidebar {
    padding-left: 30px;

    @include media-breakpoint-down(lg) {
        padding-left: 20px;
    }

    @include media-breakpoint-down(xs) {
        padding-left: 0;
    }

    &.sidebar-with-border {
        height: 100%;
        border-left: 2px solid $color-semi-primary;

        @include media-breakpoint-down(xs) {
            margin-top: px-to-rem(30px);
            border: 0;
        }
    }
}

.sidebar-row {
    @include media-breakpoint-up(sm) {
        display: flex;
    }
}
