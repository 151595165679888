.cabin-price-model {
    display: block;
    height: auto;
    margin-top: 40px;
    transition: opacity .2s ease-in-out 125ms;
    opacity: 1;
    visibility: visible;

    @include media-breakpoint-down(sm) {
        margin-top: 30px;
    }

    &.hidden-anim {
        height: 0;
        margin-top: 0;
        opacity: 0;
        visibility: hidden;
    }
}

.price-model {
    .inner,
    .inner-short-wrapper {
        display: block;
        transition: opacity .25s ease-in-out;
        background-color: $color-light-blue;
        opacity: 0;

        &.show {
            height: auto;
            opacity: 1;
            visibility: visible;
        }

        &.hidden-anim {
            height: 0;
            visibility: hidden;
        }
    }

    .subline {
        @include font(copy-bold);
        padding: 20px;
    }

    .wrapper,
    .wrapper-short {
        display: flex;
        flex-direction: row;
        padding: 15px 15px 0 15px;

        @include media-breakpoint-down(sm) {
            flex-direction: column;
        }
    }

    .part {
        flex: 1 1 auto;

        @include media-breakpoint-down(sm) {
            margin-bottom: 15px;
        }

        .detail {
            position: relative;
            padding: 30px 15px 0 15px;
        }

        .headline {
            @include font(headline);
            margin-bottom: 5px;
        }

        .cabin-price-cta-wrapper {
            padding: 10px 0 0 5px;
            text-align: center;

            .price-daily {
                @include font(copy-s);
                height: 22px;
                line-height: 20px;
            }

            .cabin-selected {
                top: 3px;
                align-items: center;
                height: 2.5rem;

                &::before {
                    padding: 2px 1px 0 0;
                }

                @include media-breakpoint-down(xs) {
                    margin-top: 0;
                }
            }

            @include media-breakpoint-down(sm) {
                padding: 10px 0 0 0;
                text-align: right;

                .cabin-selected::before {
                    padding: 2px 4px 0 0;
                }
            }
        }

        &.has-1 {
            .cabin-price-cta-wrapper {
                text-align: right;
            }

            .cabin-selected::before {
                padding: 2px 4px 0 0;
            }
        }

        &.has-2 {
            width: 50%;

            @include media-breakpoint-down(sm) {
                width: unset;
            }
        }

        &.has-3 {
            width: 32%;

            @include media-breakpoint-down(sm) {
                width: unset;
            }
        }

        &.has-4 {
            width: 24%;
            @include media-breakpoint-down(sm) {
                width: unset;
            }

            .detail {
                padding: 30px 15px 0;
            }

            .headline {
                @include font(headline-s);
            }

            .intro,
            .point {
                @include font(copy-s-has4);
                line-height: 16px;
            }

            .headline,
            .intro {
                margin: 0 -10px;
                @include media-breakpoint-down(md) {
                    margin: 0;
                }
            }

            .cabin-price-cta-wrapper {
                padding: 10px 0 0 0;
                margin: 0 -15px;
                @include media-breakpoint-down(xs) {
                    margin: 0;
                }

                .price-daily {
                    @include font(copy-s-has4);
                    height: 18px;
                    line-height: 16px;
                }

                .tarif-available,
                .cabin-selected {
                    @include font(button-has4);
                    margin-left: 0;
                    margin-right: 0;
                    min-width: 90px;
                    padding: 0 .7rem;
                    height: 2rem;
                    margin-bottom: .4rem;

                    @include media-breakpoint-down(md) {
                        margin-left: 15px;
                        margin-right: 15px;
                    }
                    @include media-breakpoint-down(xs) {
                        margin-left: 0;
                        margin-right: 0;
                    }
                }
                @include media-breakpoint-down(md) {
                    .cabin-selected {
                        padding: 0;
                    }
                }
            }

            .tarif-price {
                min-width: unset;
            }

            .p1 {
                @include font(copy-bold-s);
            }

            .p2 {
                @include font(headline-bold-s);
            }

            .bullet-points {
                padding-top: 20px;
            }

            .vip-hint {
                @include font(copy-s-has4);
                margin-left: 9px;
            }
        }

        .tarif-price {
            display: inline-block;
            min-width: 170px;
            padding-bottom: 10px;
            vertical-align: bottom;
        }

        .tarif-available {
            min-width: 150px;
            margin-right: 15px;
            margin-left: 15px;

            @include media-breakpoint-down(xs) {
                display: flex;
                top: 0;
                width: 100%;
                margin-top: 16px;
                margin-left: 0;
            }
        }

        .intro,
        .point {
            @include font(copy-s);
            display: flex;
            align-items: flex-start;
            height: 74px;
            line-height: .9rem;
        }

        .intro {
            align-items: center;
            overflow: hidden;
        }

        .bullet-points {
            margin: 0 -15px 0 -30px;
            padding-top: 40px;

            @include media-breakpoint-down(sm) {
                margin: 0;
            }
        }

        .point {
            align-items: center;
            padding: 0 15px 0 30px;
            border-top: solid 1px $color-dark-blue;

            @include media-breakpoint-down(sm) {
                padding: 0;

                &.no-point {
                    display: none;
                }
            }
        }

        .bullet-list-small {
            display: none;
            @include media-breakpoint-down(sm) {
                @include font(copy-bold);
                display: block;
                position: relative;
                padding: 0 0 12px 0;
            }
        }

        .listing-small {
            @include media-breakpoint-down(sm) {
                transition: opacity .25s ease-in-out;
                opacity: 0;
            }
        }

        .show-small {
            @include media-breakpoint-down(sm) {
                height: auto;
                opacity: 1;
                visibility: visible;
            }
        }

        .hidden-small {

            @include media-breakpoint-down(sm) {
                height: 0;
                visibility: hidden;
            }
        }

        .vip-hint {
            @include font(copy-s);
            margin-bottom: 0;
            padding: 10px 0 0 0;
            background-color: $color-light-blue;
            text-align: left;
            white-space: nowrap;

            .extern {
                > a {
                    text-decoration: underline;
                }
            }
        }
    }

    .legal {
        @include font(copy-s);
        padding: 5px 15px;
        text-align: right;
    }

    .part:nth-child(1) {
        .detail {
            background-color: $color-price-model-1;

            .bullet-points {
                margin: 0 -15px;

                @include media-breakpoint-down(sm) {
                    margin: 0;
                }
            }

            .point {
                padding: 0 15px;

                @include media-breakpoint-down(sm) {
                    padding: 0;
                }
            }
        }
    }

    .part:nth-child(2) {
        .detail {
            margin-left: 15px;
            background-color: $color-price-model-2;

            @include media-breakpoint-down(sm) {
                margin-left: 0;
            }
        }
    }

    .part:nth-child(3) {
        .detail {
            margin-left: 15px;
            background-color: $color-price-model-3;

            @include media-breakpoint-down(sm) {
                margin-left: 0;
            }
        }
    }

    .part:nth-child(4) {
        .detail {
            margin-left: 15px;
            background-color: $color-price-model-4;

            @include media-breakpoint-down(sm) {
                margin-left: 0;
            }
        }
    }

    .part.has-4:nth-child(2) > .detail,
    .part.has-4:nth-child(3) > .detail,
    .part.has-4:nth-child(4) > .detail {
        margin-left: 9px;
        @include media-breakpoint-down(sm) {
            margin-left: 0;
        }
    }

    .part .detail.part-selected {
        background-color: $color-white;
    }

    .inner-short-wrapper {
        background-color: $color-white;
    }

    .wrapper-short {
        .part {
            padding-bottom: 20px;
            border: 1px solid $color-price-model-select-border;
        }

        .part.has-4 {
            .detail {
                padding: 30px 5px 0;
            }
        }

        .part:nth-child(2),
        .part:nth-child(3) {
            margin-left: 15px;

            @include media-breakpoint-down(sm) {
                margin-left: 0;
            }
        }

        .part.has-4:nth-child(2),
        .part.has-4:nth-child(3),
        .part.has-4:nth-child(4) {
            margin-left: 5px;
            @include media-breakpoint-down(sm) {
                margin-left: 0;
            }
        }

        .part:nth-child(1) > .detail,
        .part:nth-child(2) > .detail,
        .part:nth-child(3) > .detail,
        .part:nth-child(4) > .detail {
            background-color: $color-white;
        }

        .part.has-4:nth-child(1) > .detail,
        .part.has-4:nth-child(2) > .detail,
        .part.has-4:nth-child(3) > .detail,
        .part.has-4:nth-child(4) > .detail {
            margin-left: 9px;
            margin-right: 6px;
        }

        .part.part-selected {
            border: 1px solid $color-dark-blue;
        }

        @include media-breakpoint-down(md) {
            .part.has-4 .cabin-price-cta-wrapper {
                margin-right: 15px;

                .cabin-selected {
                    margin-right: 0;
                }
            }
        }

        @include media-breakpoint-down(sm) {
            .part.has-4 .cabin-price-cta-wrapper {
                margin-right: 0;
            }
        }
    }

    .selected {
        position: relative;
        padding: 20px 45px 20px 20px;
        background-color: $color-white;

        >.arrow {
            right: 5px;
        }
    }

    .price-model-hint {
        padding: 10px 15px 20px 15px;
        text-align: center;

        @include media-breakpoint-down(sm) {
            padding: 0 15px 20px 15px;
        }

        .extern {
            padding: 10px 15px 10px 15px;
            border-top: solid 1px $color-dark-blue;

            > a {
                text-decoration: underline;
            }
        }
    }

    .inner-short-wrapper > .price-model-hint > .extern {
        border-top: solid 0 $color-dark-blue;
    }
}
