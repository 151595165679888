.insurance-selector-insurance-assignments {
    margin-bottom: px-to-rem(20px);

    .insurance-assignment-headline {
        margin-bottom: px-to-rem(5px);

        > .icon:first-child {
            position: relative;
            bottom: 2px;
            font-size: 2rem;
            vertical-align: middle;
        }
    }

    .service-price {
        @include font(copy-bold);
        text-align: right;

        .price {
            @include font(headline-bold-l);
            white-space: nowrap;
        }
    }
}

.insurances-are-blocked {
    .insurance-selector-insurance-assignments {
        margin-bottom: 0;
    }

    .insurance-selector-insurance-assignment:last-child {
        .booking-page-section {
            border-bottom: none;
            padding-bottom: 0;
        }
    }
}
