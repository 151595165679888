.page-viewport {
    position: absolute;
    top: 0;
    width: 100%;
    min-height: 100%;
}

.page-wrapper {
    min-height: 100%;
}

.page-main {
    position: relative;
}

// adds an extra padding depending on the breakpoint
.container.container-with-padding {
    $page-padding: 10px;
    margin: 0 auto;
    padding: 0 $page-padding;

    @include media-breakpoint-up(sm) {
        $page-padding: 28px;
        padding: 0 $page-padding;
    }

    @include media-breakpoint-up(lg) {
        $page-padding: 50px;
        padding: 0 $page-padding;
    }

    @include media-breakpoint-up(xl) {
        $page-padding: 70px;
        padding: 0 $page-padding;
    }
}

.nobr {
    white-space: nowrap;
}

.ms-italic {
    font-style: italic;
}

// increase the default gutter width of 10px to 20px for all breakpoints >= sm
@include media-breakpoint-up(sm) {
    .container {
        @include make-container($grid-gutter-width-sm-up);
    }

    .row {
        @include make-row($grid-gutter-width-sm-up);
    }

    @each $breakpoint in map-keys($grid-breakpoints) {
        @for $i from 1 through $grid-columns {
            .col-#{$breakpoint}-#{$i} {
                padding-right: ($grid-gutter-width-sm-up / 2);
                padding-left: ($grid-gutter-width-sm-up / 2);
            }
        }
    }
}

.row.columns-10 {
    @include make-row();
    @include make-grid-columns(10);
}

@include media-breakpoint-up(sm) {
    .row.columns-10 {
        @include make-row($grid-gutter-width-sm-up);

        @each $breakpoint in map-keys($grid-breakpoints) {
            @for $i from 1 through $grid-columns {
                .col-#{$breakpoint}-#{$i} {
                    padding-right: ($grid-gutter-width-sm-up / 2);
                    padding-left: ($grid-gutter-width-sm-up / 2);
                }
            }
        }
    }
}
