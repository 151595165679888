.campaign-code-view {
    .button.submit {
        min-width: 130px;
    }

    .section-headline.smaller {
        @include font(copy-bold-s);
        margin-bottom: 6px;
        padding-right: 40px;
        padding-top: 10px;
    }

    .detail {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0 8px 0;
        border-bottom: 1px solid $color-semi-primary-disabled;

        &:nth-child(1) {
            border-bottom: 1px solid transparent;
        }
    }

    .left-side {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
    }

    .item-value {
        @include font(copy-bold);
        line-height: 1.1rem;
    }

    .delete {
        position: relative;
        height: 40px;
        width: 40px;
        min-width: 40px;
        background-image: url('../images/delete.svg');
        background-repeat: no-repeat;
        background-position: center top;
        background-size: 40px 40px;
        text-align: center;

        &:hover {
            cursor: pointer;
        }
    }

    .delete.disabled {
        opacity: .5;
        pointer-events: none;

        &:hover {
            cursor: initial;
        }
    }

    .delete-waiting {
        position: relative;
        width: 40px;
        height: 40px;

        .loader {
            position: absolute;
            right: px-to-rem(10px);
            width: 25px;
            height: 25px;
            margin-top: -12px;
            margin-left: 7px;
            border-width: .125em;
        }
    }
}

.overlay-campaign-code {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    .stupid-extra-for-that {
        display: none;
    }

    @include media-breakpoint-down(xs) {
        flex-direction: column;
        align-items: initial;

        .stupid-extra-for-that {
            position: fixed;
            display: block;
            width: 40px;
            height: 40px;
            top: 25px;
            right: 25px;
            background-color: $color-white;
            z-index: 1;
        }
    }

    .overlay-headline {
        margin-bottom: .75rem;
        word-break: break-word;
        @include media-breakpoint-down(xs) {
            margin-right: 0;
        }
    }

    .ok {
        position: relative;
        height: 60px;
        width: 60px;
        min-width: 60px;
        margin: 0 10px 0 -10px;
        background-image: url('../images/ok.svg');
        background-repeat: no-repeat;
        background-position: center top;
        background-size: 60px 60px;
        text-align: center;

        @include media-breakpoint-down(xs) {
            display: flex;
            align-self: center;
            margin: 8px 0 25px 0;
        }
    }

    .not-ok {
        position: relative;
        height: 60px;
        width: 60px;
        min-width: 60px;
        margin: 0 10px 0 -10px;
        background-image: url('../images/not-ok.svg');
        background-repeat: no-repeat;
        background-position: center top;
        background-size: 60px 60px;
        text-align: center;

        @include media-breakpoint-down(xs) {
            display: flex;
            align-self: center;
            margin: 8px 0 25px 0;
        }
    }

    .button {
        display: none;
    }

    @include media-breakpoint-down(xs) {
        .button {
            display: initial;
            margin-top: 30px;
        }
    }
}

.overlay-campaign-code-exit {
    > .click-wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .alert.confirm {
        max-width: 720px;

        @include media-breakpoint-down(xs) {
            top: 10px;
            width: 95%;
        }
    }

    .alert-headline {
        margin-right: 0;
        margin-bottom: .75rem;
        word-break: break-word;
    }

    .alert-text {
        word-break: break-word;
    }

    .alert-buttons {
        display: flex;
        justify-content: flex-end;

        @include media-breakpoint-down(xs) {
            flex-direction: column;
        }
    }

    .button {
        border: 1px solid $color-dark-blue;

        &:hover {
            border: 1px solid $button-color-hover;
        }
    }

    .button-secondary {
        margin-right: 16px;

        @include media-breakpoint-down(xs) {
            margin-right: 0;
            margin-bottom: 16px;
        }

        &:hover {
            background-color: $button-color-hover;
            color: $button-color-light;
            border: 1px solid $button-color-hover;
        }
    }
}
