.insurance-selector-coverage-status {
    > p {
        margin-bottom: px-to-rem(10px);
        padding-bottom: 1em;
        border-bottom: 2px solid $color-semi-primary;

        @include media-breakpoint-up(sm) {
            margin-bottom: px-to-rem(30px);
        }

        .icon {
            color: $color-dark-turquoise;
        }

        &.has-error,
        &.has-error .icon {
            color: $color-error-red;
        }
    }
}
