.print-page {
    padding-top: 60px;
    font-size: 14px;
    line-height: 20px;

    .booking-page-headline {
        margin-bottom: 0;
        font-family: $tui-type-bold;
        font-size: 2em;
    }

    .page-header-logo-bar {
        text-align: left;

        svg {
            margin-top: 0;
        }
    }

    p {
        margin: 0;
    }

    .map {
        padding-top: 6px;
    }

    .spacer {
        margin-bottom: 6px;
    }

    .cabin-wrapper {
        page-break-inside: avoid;
    }

    .detail-row {
        margin-bottom: 5px;
    }

    .offer-headline,
    .travel-itinerary-title {
        font-family: $tui-type-bold;
        font-size: 1.3em;
    }

    .travel-itinerary {
        padding: 5px 10px;
        border: 1px solid $color-dark-blue;

        .table-headline {
            font-family: $tui-type-bold;
            font-size: 1em;
        }

        .table-values {
            font-family: $tui-type-regular;
            font-size: .9em;
        }

        .tender-info-port {
            position: relative;
        }

        .tender-info-icon {
            position: absolute;
            top: 5px;
            padding-left: 5px;
        }
    }

    .tender-info {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-family: $tui-type-regular;
        font-size: .9em;

        .icon-tender-hafen {
            margin-right: 3px;
            margin-left: 2px;
        }
    }

    .right-box {
        margin-top: 20px;
        padding: 5px 10px;
        border: 1px solid $color-dark-blue;

        .box-headline {
            font-family: $tui-type-bold;
            font-size: 1.3em;
        }

        .name {
            font-family: $tui-type-bold;
            font-size: 1em;
        }

        .underline {
            text-decoration: underline;
        }
    }

    .personal-offer {
        padding-top: 20px;

        .label {
            font-family: $tui-type-bold;
        }

        .personal-offer-headline {
            font-family: $tui-type-bold;
            font-size: 1.3em;
        }
    }

    .price-participant {
        font-family: $tui-type-bold;
    }

    .price-discount {
        font-size: .8em;
        line-height: 1.1;
    }

    .price-total {
        margin-top: 10px;
        padding-top: 5px;
        border-top: 1px solid $color-dark-blue;
        font-family: $tui-type-bold;
        font-size: 1.3em;
    }

    .price-value {
        text-align: right;
    }

    .benefit {
        break-inside: avoid;

        li {
            position: relative;
        }

        .icon {
            display: block;
            position: absolute;
            top: 4px;
            left: 2px;
            font-size: 1.2em;
        }

        .text {
            display: block;
            position: relative;
            padding-left: 24px;
            line-height: 24px;
        }

        sup {
            font-size: smaller;
            line-height: .1;
            vertical-align: super;
        }
    }

    .benefit-headline,
    .code-headline {
        font-family: $tui-type-bold;
        font-size: 1.3em;
    }

    .code-headline {
        page-break-before: always;
    }

    .offer-small {
        font-size: 1em;
    }

    .underline {
        text-decoration: underline;
    }

    .code-table {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 10px 0 10px 0;
        border: 1px solid $color-dark-blue;
    }

    .code-td {
        flex: 1;
        border-right: 1px solid $color-dark-blue;
        border-left: 1px solid $color-dark-blue;
        font-size: 1em;
    }

    .code-head {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 3em;
        padding: 2px 5px;
        background-color: $color-bg-booking-status;
        font-family: $tui-type-bold;
        text-align: center;
    }

    .code-value {
        padding: 5px;
        text-align: center;
    }

    @media print {
        padding-top: 20px;

        .travel-itinerary {
            .table-headline {
                font-size: .8em;
            }

            .table-values {
                font-size: .6em;
                line-height: 1.5em;
            }

            .tender-info-icon {
                top: 3px;
                padding-left: 3px;
            }
        }

        .tender-info {
            font-size: .6em;

            .icon-tender-hafen {
                padding-top: 2px;
            }
        }

        .benefit {
            font-size: .85em;

            .text {
                line-height: 22px;
            }
        }

        .offer-small {
            font-size: .7em;
        }

        .code-head {
            border-bottom: 1px solid $color-dark-blue;
            background-color: $color-white;
        }

        .icon-tender-hafen,
        .checkmark-list > li::before {
            display: inline-block;
        }

        .code-value {
            padding: 0;
        }

        .page-footer-link-bar {
            padding: 15px 0 0 0;
        }
    }
}
