.mobile-navigation {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 0;
    overflow: hidden;
    z-index: z-index(overlay);

    @include media-breakpoint-up(xl) {
        display: none;
    }

    &::before {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: $color-dark-blue;
        content: '';
        opacity: 0;
        z-index: z-index(below);
    }

    &::after {
        display: table;
        clear: both;
        content: '';
    }
}

/* stylelint-disable-next-line selector-class-pattern */
.mobile-navigation__drawer {
    height: 100%;
    transform: translateX(-100%);
}

/* stylelint-disable-next-line selector-class-pattern */
.mobile-navigation__content {
    display: block;
    position: relative;
    width: 88vw;
    max-width: 500px;
    height: 100%;
    float: left;
    background: $color-light-blue;
    overflow: auto;
}

/* stylelint-disable-next-line selector-class-pattern */
.mobile-navigation__list-item {
    padding: 15px 0;
    border-bottom: 1px inset rgba($color-dark-blue, .5);

    &:not(&--check) {
        .button {
            font-size: 25px;

            [class^='icon-'] {
                font-size: 20px;
            }
        }
    }

    &--back {
        background: $color-turquoise;
    }

    .mobile-navigation-back-title::first-letter {
        text-transform: uppercase;
    }

    &--check {
        padding: 10px 0 0;
        border-bottom: 0;
    }

    &--foldable {
        position: relative;

        &::after {
            @extend [class^='icon-'];
            @extend .icon-drop-down::before;
            position: absolute;
            top: 30px;
            right: 20px;
            pointer-events: none;
        }

        /* stylelint-disable-next-line selector-class-pattern */
        &.mobile-navigation__list-item--unfolded {
            &::after {
                @extend .icon-drop-up::before;
            }
        }
    }
}

/* stylelint-disable-next-line selector-class-pattern */
.mobile-navigation__list-item-content {
    padding: 20px 0 0 52px;
}

/* stylelint-disable-next-line selector-class-pattern */
.mobile-navigation__phone-link {
    border-bottom: 1px solid $color-dark-blue;
    font-size: 25px;
    line-height: 1.2;
}

/* stylelint-disable-next-line selector-class-pattern */
.mobile-navigation__list-item--foldable .mobile-navigation__list-item-content {
    display: none;
}

/* stylelint-disable-next-line selector-class-pattern */
.mobile-navigation__list-item--unfolded .mobile-navigation__list-item-content {
    display: block;
}

/* stylelint-disable-next-line selector-class-pattern */
.mobile-navigation__closer {
    margin: 10px 0 0 10px;
    float: left;
    background: $color-transparent;
    color: $color-white;
    font-size: 25px;
    opacity: 0;

    @include media-breakpoint-down(xs) {
        margin: 5px 0 0 5px;
    }
}

/* stylelint-disable-next-line selector-class-pattern */
.mobile-navigation--animatable {
    width: 100%;

    /* stylelint-disable selector-class-pattern */
    &::before,
    .mobile-navigation__closer {
        transition: opacity .3s ease-out;
        will-change: opacity;
    }

    /* stylelint-enable selector-class-pattern */

    /* stylelint-disable-next-line selector-class-pattern */
    .mobile-navigation__drawer {
        transition: transform .3s ease-out;
        will-change: transform;
    }
}

/* stylelint-disable-next-line selector-class-pattern */
.mobile-navigation--open {
    &::before {
        opacity: .6;
    }

    /* stylelint-disable-next-line selector-class-pattern */
    .mobile-navigation__closer {
        opacity: 1;
    }

    /* stylelint-disable-next-line selector-class-pattern */
    .mobile-navigation__drawer {
        transform: translateX(0);
    }
}
