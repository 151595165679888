.button {
    @include font(button);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: $form-element-height;
    padding: 0 1rem;
    border: 0;
    outline: 0;
    background-color: $button-color-dark;
    color: $button-color-light;
    text-decoration: none;
    white-space: nowrap;
    cursor: pointer;
    vertical-align: middle;
    user-select: none;

    @include media-breakpoint-down(xs) {
        padding: 0 .5rem;
    }

    [class^='icon-'],
    [class*=' icon-'] {
        margin: 0 .5rem 0 0;
    }

    [class='icon-small-arrow-right'] {
        margin: 0 0 0 .5rem;
    }

    span + [class^='icon-'],
    span + [class*=' icon-'] {
        margin: 0 0 0 .5rem;
    }

    &.button-block {
        display: flex;
        width: 100%;
    }

    &.button-light {
        background-color: $button-color-light;
        color: $button-color-dark;
    }

    &.button-cta {
        background-color: $button-color-cta;
        color: $button-color-light;
    }

    &.button-secondary {
        background-color: $color-transparent;
        color: $button-color-dark;

        &:hover {
            background-color: $color-transparent;
            color: $button-color-dark;
        }

        &.button-light {
            color: $button-color-light;

            &:hover {
                color: $button-color-light;
            }
        }
    }

    &.button-previous-page {
        padding-left: 0;
    }

    &.button-icon-only {
        width: $form-element-height;
        pointer-events: none;

        [class^='icon-'],
        [class*=' icon-'] {
            margin: 0;
        }
    }

    &.button-large-icon {
        [class^='icon-'],
        [class*=' icon-'] {
            font-size: 1.25em;
        }
    }

    &.button-alike {
        pointer-events: none;
    }

    &.button-left-aligned {
        justify-content: flex-start;
    }

    &:hover {
        background-color: $button-color-hover;
        color: $button-color-light;
        text-decoration: none;
    }

    &.disabled,
    &:disabled {
        opacity: $button-opacity-disabled;
        pointer-events: none;
    }

    &.is-hidden {
        display: none;
    }
}
