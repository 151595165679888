@font-face {
    /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword, font-family-name-quotes */
    font-family: 'tuic-ibe-icons';
    font-style: normal;
    font-weight: normal;
    /* stylelint-disable-next-line declaration-colon-newline-after */
    src: url('../fonts/tuic-ibe-icons/tuic-ibe-icons.ttf?fzrk2m') format('truetype'),
        url('../fonts/tuic-ibe-icons/tuic-ibe-icons.woff?fzrk2m') format('woff'),
        url('../fonts/tuic-ibe-icons/tuic-ibe-icons.svg?fzrk2m#tuic-ibe-icons') format('svg');
    font-display: swap;
}

[class^='icon-'],
[class*=' icon-'] {
    // use !important to prevent issues with browser extensions that change fonts
    /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword, font-family-name-quotes */
    font-family: 'tuic-ibe-icons' !important;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    text-transform: none;
    speak: none;

    // Better Font Rendering ===========
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-zeitplan::before {
    content: '\e930';
}

.icon-trinkgeld::before {
    content: '\e933';
}

.icon-sport::before {
    content: '\e934';
}

.icon-spa::before {
    content: '\e935';
}

.icon-programm::before {
    content: '\e936';
}

.icon-kofferservice::before {
    content: '\e937';
}

.icon-kinderbetreuung::before {
    content: '\e938';
}

.icon-kaffee::before {
    content: '\e944';
}

.icon-kabinenservice::before {
    content: '\e945';
}

.icon-getraenke::before {
    content: '\e946';
}

.icon-genuss::before {
    content: '\e947';
}

.icon-espresso::before {
    content: '\e948';
}

.icon-entertainment::before {
    content: '\e949';
}

.icon-eis::before {
    content: '\e94a';
}

.icon-allergiker::before {
    content: '\e94b';
}

.icon-tender-hafen::before {
    content: '\e943';
}

.icon-cabin-type::before {
    content: '\e942';
}

.icon-angebote::before {
    content: '\e940';
}

.icon-wlan::before {
    content: '\e941';
}

.icon-attention::before {
    content: '\e93f';
}

.icon-info-full::before {
    content: '\e93e';
}

.icon-download::before {
    content: '\e93d';
}

.icon-refresh::before {
    content: '\e93c';
}

.icon-ic-edit::before {
    content: '\e93a';
}

.icon-thumbs-up::before {
    content: '\e93b';
}

.icon-ic-x::before {
    content: '\e939';
}

.icon-ssl-icon::before,
.icon-ssl::before {
    content: '\e913';
}

.icon-exclamation-triangle::before {
    content: '\e92f';
}

.icon-plane-landing::before {
    content: '\e931';
}

.icon-icon-plane-starting::before {
    content: '\e932';
}

.icon-insurance-couple-empty::before {
    content: '\e92c';
}

.icon-insurance-family-empty::before {
    content: '\e92d';
}

.icon-insurance-single-empty::before {
    content: '\e92e';
}

.icon-insurance-couple::before {
    content: '\e904';
}

.icon-insurance-family::before {
    content: '\e909';
}

.icon-insurance-single::before {
    content: '\e90a';
}

.icon-pdf::before {
    content: '\e90b';
}

.icon-print::before {
    content: '\e90c';
}

.icon-rating-star-empty::before {
    content: '\e90d';
}

.icon-rating-star-full::before {
    content: '\e90e';
}

.icon-ship-transfer::before {
    content: '\e90f';
}

.icon-step-cabin-check::before {
    content: '\e914';
}

.icon-step-cabin::before {
    content: '\e915';
}

.icon-step-getting-there-check::before {
    content: '\e916';
}

.icon-step-getting-there::before {
    content: '\e917';
}

.icon-step-insurance-check::before {
    content: '\e91c';
}

.icon-step-insurance::before {
    content: '\e91d';
}

.icon-step-overview-check::before {
    content: '\e91e';
}

.icon-step-overview::before {
    content: '\e920';
}

.icon-step-payment-check::before {
    content: '\e921';
}

.icon-step-payment::before {
    content: '\e922';
}

.icon-step-personal-data-check::before {
    content: '\e923';
}

.icon-step-personal-data::before {
    content: '\e924';
}

.icon-telephone::before {
    content: '\e925';
}

.icon-info::before {
    content: '\e926';
}

.icon-exclamation-mark::before {
    content: '\e927';
}

.icon-email::before {
    content: '\e928';
}

.icon-edit::before {
    content: '\e929';
}

.icon-checkmark::before {
    content: '\e92a';
}

.icon-arrow-down::before {
    content: '\e900';
}

.icon-arrow-left::before {
    content: '\e901';
}

.icon-arrow-right::before {
    content: '\e902';
}

.icon-arrow-top::before {
    content: '\e903';
}

.icon-close-large::before {
    content: '\e905';
}

.icon-close-small::before {
    content: '\e906';
}

.icon-drop-down::before {
    content: '\e907';
}

.icon-drop-up::before {
    content: '\e908';
}

.icon-magnifier-minus::before {
    content: '\e910';
}

.icon-magnifier-plus::before {
    content: '\e911';
}

.icon-magnifier::before {
    content: '\e912';
}

.icon-small-arrow-down::before {
    content: '\e918';
}

.icon-small-arrow-left::before {
    content: '\e919';
}

.icon-small-arrow-right::before {
    content: '\e91a';
}

.icon-small-arrow-top::before {
    content: '\e91b';
}

.icon-step-confirmation::before {
    content: '\e91f';
}

.icon-roomsize::before {
    content: '\e92b';
}
