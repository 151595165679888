.overlay-container {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba($color-primary, .8);
    z-index: z-index(overlay);

    @include media-breakpoint-down(xs) {
        display: block;
        overflow: auto;
    }

    &.is-hidden {
        display: none;
    }

    > .container {
        width: 100%;
    }

    > .click-wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
    }
}

.overlay {
    @include font(copy);
    display: block;
    max-width: 100%;
    max-height: 90vh; // '90%' does not work here in conjunction with position:relative
    margin: 0 auto;
    padding: 25px 30px 20px;
    background: $color-white;
    overflow: auto;

    @include media-breakpoint-down(xs) {
        max-width: none;
        max-height: none;
        margin: 10px 0;
        padding-right: 15px;
        padding-left: 15px;
    }

    &.overlay-default {

        @include media-breakpoint-up(sm) {
            width: 400px;
        }

        a.link {
            text-decoration: underline;
        }
    }

    .overlay-close {
        display: flex;
        position: absolute;
        top: 30px;
        right: 30px;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border: 0;
        background-color: $color-primary;
        color: $color-white;
        font-size: 1rem;
        cursor: pointer;
        z-index: z-index(default);

        @include media-breakpoint-down(xs) {
            position: fixed;
            top: 25px;
            right: 25px;
        }

        &::before {
            @extend [class^='icon-'];
            @extend .icon-close-small::before;
        }
    }

    .overlay-headline {
        @include font(headline);
        margin-right: px-to-rem(60px);
        margin-bottom: px-to-rem(30px);
    }

    .overlay-close-wrapper-bottom {
        margin-top: 20px;

        @include media-breakpoint-down(xs) {
            .button {
                display: flex;
            }
        }

        @include media-breakpoint-up(sm) {
            .button-secondary {
                padding-left: 0;
            }
        }
    }

    u {
        text-decoration: none;
    }
}

.row-menu {
    position: absolute;
    top: 70px;
    width: 80%;
}

.overlay-inactivity {
    img {
        @include media-breakpoint-down(sm) {
            margin-bottom: 20px;
        }
    }
}

.overlay-travel-flight-train {
    .overlay-headline {
        padding-bottom: .5em;
    }
}

.overlay-vip-hint {
    .checkmark-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        @include media-breakpoint-down(xs) {
            flex-direction: column;
        }

        >li {
            flex: 1 1 auto;
            width: 50%;

            @include media-breakpoint-down(xs) {
                width: 100%;
            }

            .text > a {
                text-decoration: underline;
            }
        }
    }
}

.overlay-route-map {
    .responsive-img {
        display: block;
        width: 100%;
        height: auto;
    }
}

.overlay-payment-paydown {
    .overlay-headline ~ a,
    p > a {
        text-decoration: underline;
    }
}
