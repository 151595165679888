.api-input-busid {
    .selected-bus {
        @include font(copy);
    }

    .related-option {
        display: flex;
        align-items: center;
        height: 70px;
        border-bottom: 2px solid $color-semi-primary;
    }

    .service-price {
        @include font(headline-bold-l);
        flex: 1;
        text-align: right;
        white-space: nowrap;

        @include media-breakpoint-down(xs) {
            @include font(headline-bold);
        }
    }

    .child-hint {
        > a {
            display: inline-block;
            text-align: left;

            &::after {
                top: -8px;
                left: 5px;
            }
        }
    }

    .choice-wrapper {
        margin-top: 30px;
    }

    .notification {
        @include font(copy-s);
        margin-bottom: 1.5rem;
        line-height: 1.15;
    }

    .bus-choice {
        text-align: right;

        @include media-breakpoint-down(xs) {
            padding-top: 20px;
        }
    }

    .bus-choice-text {
        @include font(copy);
        padding-right: 8px;
        vertical-align: middle;
    }
}
