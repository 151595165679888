@media print {
    .page-header-info-bar,
    .page-header-navigation-bar,
    .page-footer-trust-bar,
    .booking-pager,
    [class^='icon-'],
    [class*=' icon-'],
    .enlarge-button,
    .print-trigger-top,
    .print-trigger-bottom,
    .service-info,
    .page-header-info-bar-menu-left,
    .hidden-for-print {
        display: none;
    }

    .print-page-break-before {
        page-break-before: always;
    }

    body {
        background-color: $color-white;

        &.navigation-fixed {
            .page {
                padding-top: 0;
            }
        }

        .page {
            padding-top: 0;
            padding-bottom: 20px;
        }

        .page-viewport {
            position: initial;
        }

        .booking-check,
        .booking-status {
            border: 1px solid $color-dark-blue;
            background-color: $color-white;
            box-shadow: none;
        }

        .booking-check > .check-price-section {
            border-top: 1px solid $color-dark-blue;
            background-color: $color-white;
        }

        .page-footer-link-bar {
            .logo-tui-cruises {
                margin-bottom: 1rem;
            }
        }
    }

    .page-main {
        position: inherit;
    }
}
