.page-travel {
    .api-step-headline {
        margin-bottom: 0;
    }

    .form-group > label {
        font-size: .8rem;
    }

    .api-step-transporttype {
        .transport-option {
            margin-bottom: px-to-rem(20px);
            border: 1px solid transparent;
            background: $color-light-blue;

            @include media-breakpoint-down(xs) {
                margin-bottom: px-to-rem(10px);
                padding: px-to-rem(10px);
            }

            &.is-selected {
                background: $color-white;

                .radiobutton-element label:hover {
                    cursor: default;
                }
            }

            &.disabled {
                &:hover {
                    .radiobutton-element label:hover {
                        cursor: default;
                    }
                }
            }
        }

        .wrapper {
            padding: 0 30px 0 30px;
            @include media-breakpoint-down(xs) {
                padding: 0 10px 0 10px;
            }
        }

        .transport-option-radio {
            @include media-breakpoint-down(xs) {
                margin-bottom: 0;
            }

            label {
                @include font(copy-bold);
                width: 100%;
                margin-left: 30px;
                padding: 34px 0 34px 47px;
                @include media-breakpoint-down(xs) {
                    margin-left: 10px;
                    padding: 15px 0 17px 47px;
                }
            }
        }

        .train-unavailable-info,
        .own-arrival-info {
            @include font(copy);
            margin-top: -6px;
            padding: 0 30px 10px 30px;
            @include media-breakpoint-down(xs) {
                padding: 0 10px 10px 10px;
            }

            a {
                cursor: pointer;
            }
        }
    }

    .home-bound-not-default {
        @include font(copy);
        margin-top: px-to-rem(-10px);
        margin-bottom: px-to-rem(20px);
        color: $color-error-red;
    }

    .airport-row {
        display: flex;
        align-items: center;

        @include media-breakpoint-down(xs) {
            display: block;
            align-items: normal;
        }
    }

    .transfer-to-ship {
        @include font(copy);
        margin: 36px 0 36px 0;
        text-align: right;

        @include media-breakpoint-down(xs) {
            @include font(copy-s);
            top: 0;
            margin-top: px-to-rem(-11px);
            margin-bottom: 14px;
            padding-right: 0;
            padding-bottom: 0;
        }

        &.round-trip {
            top: 0;
            margin: 0 0 20px 0;

            @include media-breakpoint-down(xs) {
                margin: 0 0 0 0;
            }
        }

        .icon {
            position: relative;
            top: 4px;
            font-size: 1.2em;
        }
    }

    .checkmark-list {
        display: flex;
        flex-wrap: wrap;

        @include media-breakpoint-down(xs) {
            flex-direction: column;
        }

        > li {
            width: 50%;
            margin-bottom: 10px;

            @include media-breakpoint-down(xs) {
                width: 100%;
            }
        }

        > li:nth-child(odd) {
            padding-right: 20px;

            @include media-breakpoint-down(xs) {
                padding-right: 0;
            }
        }
    }

    .content-advantage {
        .checklist {
            @include font(copy, $color-primary);
            margin: 30px 0 30px 0;
            padding: 27px 0 0 0;
            border-top: 1px solid $color-semi-primary;

            @include media-breakpoint-down(xs) {
                margin-bottom: 14px;
                padding: .63rem 0 0 0;
            }

            .checklist-headline {
                margin-top: 0;
                margin-bottom: 23px;
            }
        }
    }

    .travel-individually {
        padding: 25px 0 20px 0;

        .headline {
            margin-bottom: .2em;
        }

        .t-i-c {
            margin: 0;
            padding: 32px 30px 20px 30px;
            background: $color-light-blue;

            @include media-breakpoint-down(xs) {
                padding: 15px 0 17px 20px;
            }

            .t-i-c1 {
                padding-right: 20px;
                padding-left: 0;

                @include media-breakpoint-down(xs) {
                    padding-bottom: 20px;
                }
            }

            .t-i-c2 {
                padding-left: 20px;

                @include media-breakpoint-down(xs) {
                    padding-left: 0;
                }
            }
        }
    }
}
