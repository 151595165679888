.trip-alternative {
    &.element {
        height: 2.5rem;
    }

    .selected {
        @include font(form-element, $form-color-dark);
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 2.5rem;
        padding-right: 50px;
        padding-left: 20px;
        background-color: $color-white;
        letter-spacing: .005rem;
        line-height: 2.5rem;
        overflow: hidden;
    }

    .select {
        position: absolute;
        border-color: $form-color-light;
        background-color: transparent;
        color: transparent;

        > option {
            color: $color-logo-dark-blue;
        }
    }

    .price {
        display: inline;
        @media (max-width: 30rem) {
            display: none;
        }
    }
}
