.insurance-carousel {
    $insurance-item-margin: 19px;

    @include media-breakpoint-down(md) {
        .swiper-button-prev,
        .swiper-button-next {
            font-size: 30px;
        }
    }

    .swiper-container {
        position: relative;
        width: 100%;
        margin: -40px 0 15px 0;
        padding: 60px 0;

        @include media-breakpoint-up(sm) {
            padding: 0;
            overflow: visible;
        }

        &::before,
        &::after {
            display: block;
            position: absolute;
            top: 60px;
            bottom: 60px;
            width: 10%;
            background-image: linear-gradient(to left, rgba($color-turquoise, 0), $color-turquoise);
            content: '';
            z-index: z-index(above);
        }

        &::before {
            left: -1px;
        }

        &::after {
            right: -1px;
            transform: rotate(180deg);
        }
    }

    .swiper-wrapper {
        display: flex;
        align-items: flex-end;

        @include media-breakpoint-down(xs) {
            align-items: center;
        }

        @include media-breakpoint-up(sm) {
            width: calc(100% + #{$insurance-item-margin});
            margin: 3rem px-to-rem(-$insurance-item-margin) 1rem 0;

            /* using !important to override dynamically added styles from slider component */
            transform: translate3d(0, 0, 0) !important;
        }
    }

    .swiper-slide {
        transform: scale(.9);
        transform-origin: center;
        transition: transform 300ms ease-out;
        background: $color-light-blue;
        pointer-events: none;

        @include media-breakpoint-up(sm) {
            position: relative;

            /* using !important to override dynamically added styles from slider component */
            /* stylelint-disable-next-line number-max-precision */
            width: calc(33.333333% - #{$insurance-item-margin}) !important;
            height: 100%;
            margin-right: $insurance-item-margin;
            float: left;
            transform: scale(1);
            pointer-events: all;
        }
    }

    .swiper-slide-active {
        transform: scale(1);
        pointer-events: all;
    }

    // slider widget functionality only for small screens
    .swiper-container::before,
    .swiper-container::after,
    .swiper-pagination,
    .swiper-button-prev,
    .swiper-button-next {
        @include media-breakpoint-up(sm) {
            display: none;
        }
    }

    .insurance-item {
        padding: 30px 20px 15px 20px;
        background-color: $color-dark-turquoise;
        line-height: px-to-rem(22px);
        text-align: center;

        .headline {
            @include font(copy-bold);
            margin-bottom: 1em;
            line-height: 1em;
        }

        .price {
            @include font(headline-bold-l);
            display: block;
        }

        p,
        ul {
            margin: 0;
            padding: 1em 0;
            border-top: 2px solid $color-semi-primary;
            font-size: px-to-rem(18px);
        }

        ul {
            margin-bottom: 5px;
            border-bottom: 2px solid $color-semi-primary;
        }
    }

    .checkmark-list {
        li {
            padding: 5px 0 8px 34px;
            text-align: left;
        }
    }

    .badge {
        padding-top: 53px;

        > div:first-child {
            position: absolute;
            top: -6px;
            left: -4px;
            padding: 5px 16px;
            background: $color-dark-blue;
            color: $color-white;
            font-size: px-to-rem(18px);
            line-height: px-to-rem(30px);
            text-align: left;
            box-shadow: 1px 2px $color-drop-shadow;
            box-sizing: border-box;

            @include media-breakpoint-down(xs) {
                top: -6px;
                left: -4px;
                width: auto;
                padding: 9px 20px;
            }

            &::before {
                position: absolute;
                bottom: -8px;
                left: 0;
                width: 0;
                height: 0;
                border-width: 0 4px 8px 0;
                border-style: solid;
                border-color: $color-transparent $color-dark-blue $color-transparent $color-transparent;
                content: '';
            }
        }
    }
}
