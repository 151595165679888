.container.main-navigation-container {
    height: get-map-value(lg, $header-heights);
    overflow: hidden;

    @include media-breakpoint-down(lg) {
        padding: 0;
    }

    @include media-breakpoint-down(md) {
        height: get-map-value(sm, $header-heights);
    }

    @include media-breakpoint-down(xs) {
        height: get-map-value(xs, $header-heights);
    }

    @include when-inside('body.navigation-fixed.navigation-collapsed') {
        @include media-breakpoint-up(lg) {
            height: get-map-value(lg-fix, $header-heights);
        }
    }
}

.main-navigation {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    height: 100%;

    $arrow-widths: (
        lg: 28px,
        lg-fix: 20px,
        sm: 24px,
        xs: 14px,
    );

    li {
        display: flex;
        position: relative;
        flex: 1 auto;
        justify-content: center;
        width: 100%;
        margin-right: 30px;

        @include media-breakpoint-down(md) {
            margin-right: get-map-value(sm, $arrow-widths);
        }

        @include media-breakpoint-down(xs) {
            margin-right: get-map-value(xs, $arrow-widths);
        }

        &:first-child {
            margin-left: 30px;

            @include media-breakpoint-down(lg) {
                margin-left: 0;
            }

            a::after {
                left: (get-map-value(lg, $arrow-widths) - 2) * -1;
            }
        }

        &:last-child {
            @include media-breakpoint-down(lg) {
                margin-right: 0;
            }
        }

        &:first-child::before,
        &::after {
            position: absolute;
            top: 50%;
            width: get-map-value(lg, $arrow-widths);
            height: 120px;
            margin-top: -60px;
            background: url('../images/navigation-arrow.svg') center right no-repeat;
            content: '';
            opacity: .5;
            z-index: z-index(above);

            @include media-breakpoint-down(md) {
                width: get-map-value(sm, $arrow-widths);
            }

            @include media-breakpoint-down(xs) {
                width: get-map-value(xs, $arrow-widths);
            }
        }

        &::after {
            right: get-map-value(lg, $arrow-widths) * -1;

            @include media-breakpoint-down(md) {
                right: get-map-value(sm, $arrow-widths) * -1;
            }

            @include media-breakpoint-down(xs) {
                right: get-map-value(xs, $arrow-widths) * -1;
            }

            @include when-inside('body.navigation-fixed.navigation-collapsed') {
                @include media-breakpoint-up(lg) {
                    right: get-map-value(lg-fix, $arrow-widths) * -1;
                }
            }
        }

        &::before {
            left: (get-map-value(lg, $arrow-widths) + 1) * -1;

            @include media-breakpoint-down(md) {
                left: get-map-value(sm, $arrow-widths) * -1;
            }

            @include media-breakpoint-down(xs) {
                left: get-map-value(xs, $arrow-widths) * -1;
            }

            @include when-inside('body.navigation-fixed.navigation-collapsed') {
                @include media-breakpoint-up(lg) {
                    left: (get-map-value(lg-fix, $arrow-widths) + 17) * -1;
                }
            }
        }

        &:first-child::before,
        &:last-child::after {
            @include media-breakpoint-down(md) {
                display: none;
            }
        }

        .link-text {
            margin-top: -4px;
            padding-bottom: 5px;
        }
    }

    a {
        @include font(button, $color-primary);
        display: flex;
        position: relative;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;

        &::after {
            display: none;
            position: absolute;
            right: -3px;
            bottom: 0;
            left: (get-map-value(lg, $arrow-widths) - 1) * -1;
            height: 7px;
            transform: skewX(-22deg);
            background-color: $color-primary;
            content: '';

            @include media-breakpoint-down(md) {
                right: -2px;
                left: (get-map-value(sm, $arrow-widths) - 2) * -1;
            }

            @include media-breakpoint-down(xs) {
                right: -2px;
                left: (get-map-value(xs, $arrow-widths) - 2) * -1;
            }
        }

        &.deactivated {
            pointer-events: none;
        }

        &:hover::after {
            display: block;
        }

        .link-text {
            height: auto;
            transition: opacity .2s ease-in-out, height .15s .1s ease;
            opacity: 1;

            &::first-letter {
                text-transform: uppercase;
            }

            @include media-breakpoint-down(md) {
                display: none;
            }

            @include when-inside('body.navigation-fixed.navigation-collapsed') {
                @include media-breakpoint-up(lg) {
                    height: 0;
                    opacity: 0;
                }
            }
        }

        [class^='icon-'],
        [class*=' icon-'] {
            font-size: px-to-rem(50px);

            @include media-breakpoint-down(xs) {
                font-size: 24px;
            }
        }
    }

    .active {
        a::after {
            display: block;
        }
    }

    .done {
        [class^='icon-'] {
            color: $color-green;
        }

        &.finished {
            cursor: default;

            a {
                cursor: default;

                &::after {
                    display: none;
                }
            }
        }
    }

    .pending {
        a {
            color: rgba($color-primary, .5);
            pointer-events: none;
        }
    }

    .not-applicable {
        display: none;
    }
}
