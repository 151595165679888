.api-step-headline {
    @include font(headline);
    margin-bottom: .75rem;

    &.disabled {
        opacity: .4;
    }
}

.step-are-blocked-hint {
    margin-bottom: 20px;
    padding: 18px 25px 21px 30px;
    border-radius: 10px;
    background-color: $color-deep-sky-blue;
    color: $color-white;

    .title {
        @include font(copy-m-bold);
    }

    .text {
        @include font(copy-m);
    }

    .all-blocked-back-button {
        margin-top: 19px;

        @include media-breakpoint-down(xs) {
            display: flex;
            width: 100%;
        }

        &:hover {
            background-color: $color-dark-blue;
        }
    }
}
