.b2b-header {
    height: 60px;
    background-color: $color-dark-blue;
    overflow: hidden;

    @include media-breakpoint-down(md) {
        display: none;
    }

    .welcome {
        @include font(button);
        color: $color-white;
        line-height: 60px;
    }

    .cta {
        @include font(button);
        display: inline-block;
        height: 60px;
        color: $color-white;
        line-height: 60px;
        cursor: pointer;
        overflow: hidden;

        .cta-text {
            line-height: 60px;
            vertical-align: top;
        }
    }
}

.b2b-examples {
    padding: 2em;

    .link:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}

/* stylelint-disable-next-line selector-class-pattern */
.mobile-navigation__list-item {
    &--b2b {
        background: $color-dark-blue;
        font-size: 25px;

        .cta-text {
            display: flex;
            justify-content: flex-start;
            width: 100%;
            padding: 0 1rem;
            color: $color-white;
            cursor: pointer;
        }

        .b2b-svg {
            margin: 0 .5rem 0 0;
        }
    }
}

.b2b-view {
    .headline {
        margin: 20px 0 10px 0;
    }

    .second {
        @include font(copy-bold);
    }

    .code-table {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 10px 0 10px 0;
        border: 1px solid $color-dark-blue;
    }

    .code-td {
        @include font(form-label);
        flex: 1 1 auto; // fix for safari (< 10.3) issue https://bugs.webkit.org/show_bug.cgi?id=136041 and IE
        border-right: 1px solid $color-dark-blue;
        border-left: 1px solid $color-dark-blue;
    }

    .code-head {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 3em;
        padding: 2px 5px;
        background-color: $color-bg-booking-status;
        text-align: center;
    }

    .code-value {
        padding: 5px;
        border-bottom: 1px solid $color-semi-primary;
        text-align: center;

        &:last-child {
            border-bottom: 0 solid $color-semi-primary;
        }
    }

    .hint {
        text-align: left;
    }

    .text {
        > a {
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .print {
        position: absolute;
        right: 0;

        .icon-print {
            margin: 0;
            padding-left: 5px;
        }
    }
}

.page-personal {
    .is-invoice {
        padding: 0;

        @include media-breakpoint-up(sm) {
            padding: 10px;
        }
    }
}

.page-payment {
    .b2b-view {
        padding-bottom: px-to-rem(20px);
    }

    .agentur.success-box > .headline {
        margin: 0;
    }
}

.page-overview {
    .b2b-overview-text {
        padding: 30px;
        background: $color-white;
        color: $color-dark-blue;
        overflow: auto;
    }
}
