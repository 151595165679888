.spinner-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color-turquoise, .8);
    text-align: center;
    z-index: z-index(spinner);
}

.loader {
    display: inline-block;
    position: relative;
    top: 50%;
    margin-top: -2em;
    transform: translateZ(0);
    border-top: .25em solid rgba($color-primary, .2);
    border-right: .25em solid rgba($color-primary, .2);
    border-bottom: .25em solid rgba($color-primary, .2);
    border-left: .25em solid $color-primary;
    font-size: 20px;
    animation: spinner-rotation 1.1s infinite linear;
}

.loader,
.loader::after {
    width: 4em;
    height: 4em;
    border-radius: 50%;
}

@keyframes spinner-rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
