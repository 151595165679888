.page {
    padding-top: $page-padding-top;
    padding-bottom: 90px;

    @include when-inside('body.navigation-fixed') {
        padding-top: $page-padding-top + get-map-value(lg, $header-heights);
    }

    @include media-breakpoint-down(md) {
        padding-top: $page-padding-top + get-map-value(sm, $booking-status-heights);

        @include when-inside('body.navigation-fixed') {
            padding-top: $page-padding-top + get-map-value(sm, $booking-status-heights) + get-map-value(sm, $header-heights);
        }
    }

    @include media-breakpoint-down(xs) {
        padding-top: $page-padding-top + get-map-value(xs, $header-heights);

        @include when-inside('body.navigation-fixed') {
            padding-top: $page-padding-top + get-map-value(xs, $booking-status-heights) + get-map-value(xs, $header-heights);
        }
    }

    &.without-booking-status {

        @include media-breakpoint-down(md) {
            padding-top: $page-padding-top;

            @include when-inside('body.navigation-fixed') {
                padding-top: $page-padding-top + get-map-value(sm, $header-heights);
            }
        }

        @include media-breakpoint-down(xs) {
            padding-top: $page-padding-top;

            @include when-inside('body.navigation-fixed') {
                padding-top: $page-padding-top + get-map-value(xs, $header-heights);
            }
        }
    }
}
