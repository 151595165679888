.enlarge-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background-color: $color-semi-primary;
    color: $color-white;
    cursor: pointer;

    &::before {
        @extend [class^='icon-'];
        @extend .icon-magnifier::before;
    }
}
