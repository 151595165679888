.overview-check {
    background-color: $color-bg-booking-status;
    box-shadow: $default-drop-shadow;
    overflow: hidden;

    @include media-breakpoint-down(xs) {
        .mood {
            margin: 0 0 -10px 0;
            padding-bottom: 20px;
        }
    }

    .check-section {
        @include font(copy-s);
        position: relative;
        margin: 0 30px;
        padding: 25px 0 20px 0;
        border-top: 1px solid $color-semi-primary;
        line-height: 1.1rem;

        &:first-of-type {
            border: 0;

            @include media-breakpoint-down(xs) {
                margin: 0 10px 0 20px;
                padding: 10px 0 20px 0;
            }
        }

        @include media-breakpoint-down(md) {
            margin: 0 20px;
        }

        @include media-breakpoint-down(xs) {
            margin: 0 20px;
            padding: 16px 0 20px 0;
        }

        .section-headline {
            @include font(copy-bold);
            margin-bottom: 6px;
            padding-right: 40px;
        }

        .per-cabin {
            padding-top: 10px;

            &.is-first {
                padding-top: 0;
            }
        }

        .participant-spacer {
            padding-top: 7px;
        }
    }

    .check-price-section {
        margin: 0 15px 15px 15px;
        background-color: $color-semi-dark-turquoise;

        @include media-breakpoint-down(xs) {
            margin: 0 5px 5px 5px;
        }

        > .check-section {
            margin: 0 15px;

            &:first-of-type {
                border: 0;
            }
        }
    }

    .edit-link {
        position: absolute;
        top: 25px;
        right: 0;
        height: auto;
        padding: 0;

        @include media-breakpoint-down(xs) {
            top: 18px;
        }
    }

    .text-wrapper {
        margin-top: -5px;
        word-break: break-word;

        @include media-breakpoint-down(xs) {
            margin-top: inherit;
        }

        .overview-travel {
            display: flex;
            flex-direction: column;
            gap: px-to-rem(24px);

            > div {
                position: relative;
            }
        }
    }

    .check-image {
        position: relative;

        > img {
            display: block;
            width: 100%;
            height: auto;
        }
    }

    .route-map {
        position: relative;
        width: 100%;
        max-width: 250px;
        cursor: pointer;

        .route-map-image {
            width: inherit;
            height: inherit;
        }

        .enlarge-button {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 36px;
            height: 36px;
            margin-top: -18px;
            margin-left: -18px;
        }
    }

    .premium-headline {
        sup {
            font-size: smaller;
            line-height: .1;
            vertical-align: super;
        }
    }

    .premium {
        @include font(copy-s);
        color: $color-dark-blue;
        line-height: .8rem;

        > li {
            position: relative;
        }

        .icon {
            position: absolute;
            top: 7px;
            left: 5px;
            font-size: 1.2rem;

            @media print {
                display: initial;
            }
        }

        .text {
            display: block;
            position: relative;
            top: 0;
            left: 0;
            padding-left: 46px;
            line-height: 36px;
        }
    }

    .discount-section {
        padding-top: .8rem;

        &:first-of-type {
            padding-top: 0;
        }
    }

    .price-per-person {
        .value {
            text-align: right;
            white-space: nowrap;
        }

        .total-value {
            @include font(copy-bold);
            line-height: 1.1rem;
        }
    }

    .total-price {
        padding-bottom: 20px;
        text-align: right;

        .price {
            @include font(headline-bold-l);
            margin-left: 5px;
        }
    }

    @media print {
        .print-tip-image {
            /* stylelint-disable-next-line number-max-precision */
            width: 33.3333333333%;
        }

        .print-trip-text {
            /* stylelint-disable-next-line number-max-precision */
            width: 41.6666666667%;
        }

        .print-route-map {
            width: 25%;
        }

        .route-map.b2b .route-map-image {
            width: 180px;
        }

        .rro > .check-section {
            &:first-of-type {
                padding: 10px 0 10px 0;
            }
        }

        .check-section {
            padding: 5px 0 10px 0;

            &.print-page-break-before {
                border-top: 1px solid transparent;
            }
        }
    }
}
