.rre-change {
    margin: 0 auto;
    max-width: 1440px;
}

.change-examples {
    background-color: white;
    padding: 20px;

    .part {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-content: space-between;
        font-size: .8em;
    }

    .box {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        width: 150px;
        height: 50px;
        margin-right: 200px;
    }

    .box2 {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        width: 150px;
        height: 50px;
        margin-right: 0;
    }

    .von1 {
        background-color: #98d4e3;
    }

    .zu1 {
        background-color: #85cee4;
    }

    .von2 {
        background-color: #c7f1ff;
    }

    .zu2 {
        background-color: #ceebf4;
    }

    .von3 {
        background-color: #c7ecf7;
    }

    .zu3 {
        background-color: #ceebf4;
    }

    .von4 {
        background-color: #eaf6f9;
    }

    .zu4 {
        background-color: #e7f5fa;
    }

    .von5 {
        background-color: #e3f8ff;
    }

    .zu5 {
        background-color: #e7f5fa;
    }

    .von6 {
        background-color: #f5a70f;
    }

    .zu6 {
        background-color: #eba6bd;
    }

    .von61 {
        margin-right: 50px;
        color: #fff;
    }

    .zu61 {
        color: #263e6a;
    }

    .von7 {
        background-color: #98cfe1;
    }

    .zu7 {
        background-color: #9dd8e9;
    }

    .von8 {
        background-color: #a4d2e1;
    }

    .zu8 {
        background-color: #9dd8e9;
    }

    .von9 {
        background-color: #afe0f0;
    }

    .zu9 {
        background-color: #b6e2ef;
    }
}
