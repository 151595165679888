strong,
b,
.bold {
    font-family: $tui-type-bold;
    font-weight: normal;
}

em,
i {
    font-family: $tui-type-regular;
    font-style: italic;
}

.is-hidden {
    display: none;
}

.is-open {
    display: block;
}

.align-right {
    text-align: right;
}

.align-left {
    text-align: left;
}

.align-center {
    text-align: center;
}

.noscroll-modal,
.noscroll {
    overflow: hidden;
}

.noscroll.noscroll-modal {

    /* stylelint-disable selector-class-pattern */

    .ReactModal__Overlay {
        visibility: hidden;
    }
    /* stylelint-enable selector-class-pattern */
}
