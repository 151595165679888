.cabin-number-overlay {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    height: 100vh;
    background-color: $color-turquoise;
    z-index: z-index(overlay);
    overflow-x: scroll;

    @include media-breakpoint-down(sm) {
        &.input {
            height: auto;
        }
    }

    .cabin-main .cabin-tab-choice .cabin-c {
        cursor: auto;
    }

    .spinner-container {
        background-color: transparent;
    }

    .overlay-close {
        display: flex;
        position: absolute;
        top: 30px;
        right: 30px;
        align-items: baseline;
        justify-content: center;
        border: 0;
        background-color: $color-turquoise;
        color: $color-primary;
        font-size: 1rem;
        cursor: pointer;
        z-index: z-index(default);

        &::after {
            @extend [class^='icon-'];
            @extend .icon-close-small::before;
            margin-left: 18px;
            font-size: 13px;
        }
    }

    .disable {
        opacity: .5;
        pointer-events: none;
    }

    .arrow {
        position: absolute;
        top: 0;
        right: -10px;
        width: 45px;
        height: 100%;
        transform: rotate3d(1, 0, 0, 0deg);
        transition: transform 150ms ease-in-out;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;
        z-index: 1; // fix safari-css-transform-3d-rotation-glitch

        &.open {
            transform: rotate3d(1, 0, 0, 180deg);
        }

        &::after {
            @extend [class^='icon-'];
            @extend .icon-drop-down::before;
            display: flex;
            position: absolute;
            top: 0;
            right: 15px;
            align-items: center;
            width: px-to-rem(20px);
            height: 100%;
            font-size: px-to-rem(20px);
            line-height: 1;
            text-align: center;
            pointer-events: none;
        }
    }
}

.cabin-number-wrapper {
    min-height: 100vh;
    padding: 100px 0 0 0;

    .decknumber {
        padding-top: 20px;
    }

    .cta-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        @include media-breakpoint-down(xs) {
            flex-direction: column-reverse;

            .available {
                width: 100%;
                margin: 0 0 20px 0;
            }
        }
    }

    .button.back {
        padding: 0 1rem 0 0;
    }

    @include media-breakpoint-down(xs) {
        .line {
            margin: 20px -10px 0 -10px;
            border-top: 2px solid $color-c-nav;
        }
    }

    .wait {
        position: relative;
        width: 196px;
        height: 50px;
        background-color: $color-dark-blue;

        @include media-breakpoint-down(xs) {
            width: 100%;
        }

        .loader {
            position: absolute;
            right: 50%;
            width: 2em;
            height: 2em;
            margin-top: -20px;
            margin-right: -20px;
            border: .25em solid rgba($color-white, .2);
            border-width: .125em;
            border-left-color: $color-white;
        }
    }
}

.select-running {
    .decknumber {
        .select-element {
            opacity: .4;
        }
    }
}

.cabin-deckplan {
    .deck-plan-hint {
        line-height: px-to-rem(24px);
        text-align: center;

        .bold {
            @include font(copy-bold);
        }

        @include media-breakpoint-down(xs) {
            padding-top: px-to-rem(20px);
        }
    }

    .wrapper-deckplan {
        min-height: 1500px;
    }

    .cabin-overlay-content {
        position: fixed;
        min-width: 350px;
        min-height: 100px;
        margin-right: 150px;
        padding: 30px;
        background: $color-white;
        box-shadow: $default-drop-shadow;
        z-index: z-index(default);

        @include media-breakpoint-down(xs) {
            display: none;
        }

        &.line-up-right {
            transform: translateX(-100%);

            &.line-up-bottom {
                transform: translate(-100%, -100%);
            }
        }

        &.line-up-bottom {
            transform: translateY(-100%);
        }

        .headline {
            margin-bottom: 0;

            span {
                @include font(copy-m);
                font-size: 1rem;
            }
        }

        .cabin-info {
            margin-bottom: px-to-rem(16px);

            [class^='icon-'],
            [class*=' icon-'] {
                font-size: 1.5em;
                line-height: 1;
                vertical-align: middle;
            }
        }

        .cabin-info-item {
            display: inline-block;
            position: relative;
            margin-right: 10px;
            line-height: 1;
            white-space: nowrap;

            .icon {
                position: relative;
                top: -3px;
            }

            .icon-insurance-single {
                margin-left: 6px;
            }
        }

        .cabin-info-item-pipe {
            position: absolute;
            width: 1px;
            height: 22px;
            margin-top: 4px;
            background-color: $color-semi-primary;
        }

        .ct-inter {
            padding-left: 20px;

            > li {
                padding-right: 20px;
                list-style: square;
            }
        }

        // TUICUNIT-1297 kleines-decks-dreieck
        @include media-breakpoint-up(xl) {
            .kd-dreieck {
                position: absolute;
                right: 0;
                width: 1px;
                height: 50px;

                &.top {
                    top: 30px;
                }

                &.bottom {
                    bottom: 68px;
                }

                &::after,
                &::before {
                    position: absolute;
                    top: 50%;
                    left: 100%;
                    width: 0;
                    height: 0;
                    border: solid transparent;
                    content: ' ';
                }

                &::after {
                    margin-top: -10px;
                    border-width: 10px 0 10px 10px;
                    border-color: transparent;
                    border-left-color: $color-white;
                }

                &::before {
                    margin-top: -10px;
                    border-width: 10px 0 10px 10px;
                    border-color: transparent;
                    border-left-color: $color-white;
                }
            }
        }
    }
}

.no-deck-plan {
    text-align: center;
}

.deck-plan-svg {
    width: 100%;

    [id^='EINRICHTUNG'] {
        display: none;
    }

    /* stylelint-disable-next-line selector-id-pattern */
    #KABINEN {
        > [id^='KABINE-'] {
            fill: $color-white;

            &.available {
                cursor: pointer;
                fill: lighten($color-green, 30%);
                pointer-events: auto;
                -webkit-tap-highlight-color: transparent;
            }
            @include media-breakpoint-up(sm) {
                &.available:hover {
                    fill: $color-green;
                }
            }

            &.selected {
                fill: $color-green;
            }

            &.selected-other {
                cursor: auto;
                fill: $color-green;
            }
        }

        > [id^='CABIN-NR-'] {
            fill: $color-black;
            pointer-events: none;

            &.highlight {
                fill: $color-white;
            }

            &.selected {
                fill: $color-white;
            }

            &.selected-other {
                fill: $color-black;
            }
        }
    }
}

/* stylelint-disable-next-line selector-id-pattern */
.disable #KABINEN {
    > [id^='KABINE-'] {
        fill: $color-white;

        &.available {
            cursor: auto;
        }

        &.available:hover {
            fill: lighten($color-green, 30%);
        }

        &.selected:hover {
            fill: $color-green;
        }
    }
}

.legend-pos1 {
    display: block;
    @include media-breakpoint-down(md) {
        display: none;
    }
}

.legend-pos2 {
    display: none;
    @include media-breakpoint-down(md) {
        display: block;

        .overlay-close-wrapper-bottom {
            margin-bottom: 30px;
            padding: 10px;
            text-align: center;
        }
    }
}

.legend-info {
    display: flex;
    flex-direction: row;
    padding-top: 50px;

    .legend,
    .info {
        width: 50%;
    }

    @include media-breakpoint-down(xs) {
        .legend {
            width: 100%;
        }

        .info {
            display: none;
        }
    }

    .deck-legend-headline {
        margin-bottom: 18px;
    }

    .deck-legend-list {
        > li {
            display: flex;
            align-items: center;

            > img,
            > svg {
                margin-right: px-to-rem(10px);
            }
        }
    }

    .deckplan-use-hint {
        position: relative;
        align-items: center;
        padding: 0 16px 14px 0;
        font-size: 16px;
        line-height: 1rem;

        > img {
            position: absolute;
            top: 50%;
            width: 48px;
            height: 48px;
            margin-top: -24px;
            margin-left: 16px;
        }

        .text {
            margin-left: 80px;
        }
    }
}
