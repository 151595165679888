@include media-breakpoint-down(md) {
    .navigation-fixed .page.page-overview {
        padding-top: 160px;
    }
}

@include media-breakpoint-down(xs) {
    .navigation-fixed .page.page-overview {
        padding-top: 114px;
    }
}

.page-overview {
    @include media-breakpoint-down(md) {
        padding-top: 60px;
    }

    .print-details {
        cursor: pointer;
    }

    .booking-page-headline {
        font-size: px-to-rem(35px);
    }

    .print-trigger-top {
        padding-bottom: px-to-rem(10px);
    }

    .print-trigger-bottom {
        padding-top: px-to-rem(10px);
    }

    .payment-hint-text {
        @include font(copy);
        text-align: left;

        > a {
            text-align: left;
        }
    }

    .booking-page-section-payment {
        padding-bottom: 30px;
    }

    .api-step-payment-type {
        .help-hint {
            > a::after {
                top: initial;
                bottom: 0;
            }
        }
    }

    .api-step-approval {
        padding-top: px-to-rem(25px);
        padding-bottom: 30px;

        a {
            color: inherit;
            text-decoration: underline;
        }
    }

    @media print {
        .booking-page-headline {
            margin-bottom: 10px;
            font-size: px-to-rem(24px);
        }
    }

    .legal {
        &.headline {
            margin-bottom: 14px;
            padding-top: 37px;

            @include media-breakpoint-down(xs) {
                padding-right: 10px;
                padding-left: 10px;
            }
        }

        &--text {
            padding-bottom: 17px;

            @include media-breakpoint-down(xs) {
                padding-right: 10px;
                padding-left: 10px;
            }

            a {
                color: inherit;
                text-decoration: underline;
            }
        }
    }

    .wait {
        position: relative;
        padding-right: px-to-rem(60px);
        background: #7390ad;
        opacity: 1;

        .loader {
            position: absolute;
            right: 2.3rem;
            width: 2em;
            height: 2em;
            margin-top: -20px;
            border-width: .125em;
        }
    }
}
