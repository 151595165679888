.api-step-train {
    .form-group {
        margin-bottom: 0;
    }

    .option-wrapper {
        &.is-round-trip {
            @include media-breakpoint-down(xs) {
                margin-top: px-to-rem(20px);
            }
        }
    }

    .party {
        @include font(copy-s);
        padding-bottom: 30px;

        .top {
            margin-bottom: .5rem;
            font-size: .8rem;
            line-height: 1rem;
        }
    }

    .api-step-headline {
        margin-bottom: px-to-rem(10px);
    }

    .travel-class-options {
        margin-top: px-to-rem(-10px);

        label {
            @include font(copy-bold);
        }

        @include media-breakpoint-down(xs) {
            margin-top: px-to-rem(-20px);
        }
    }

    .tarif {
        display: flex;
        flex-direction: row;
        padding: 15px 0 0 0;

        @include media-breakpoint-down(sm) {
            flex-direction: column;
        }
        @include font(copy-s);

        .part {
            display: flex;
            flex: 1 1 auto;
            flex-direction: column;
            justify-content: space-between;
            padding: 0 20px 20px 20px;
            border: 1px solid $color-form-border;

            &.has-2 {
                width: 50%;

                @include media-breakpoint-down(sm) {
                    width: 100%;
                }
            }

            &.selected {
                border: 1px solid $color-dark-blue;
            }

            @include media-breakpoint-down(sm) {
                padding: 0 10px 10px 10px;
            }

            .head {
                margin-bottom: 20px;
                border-bottom: 2px solid $color-form-border;
                line-height: 50px;
            }

            .api-step-headline {
                padding: 0 20px;

                @include media-breakpoint-down(sm) {
                    padding: 0 10px;
                }
            }
        }

        .part:nth-child(2) {
            margin-left: 20px;

            @include media-breakpoint-down(sm) {
                margin-top: 20px;
                margin-left: 0;
            }
        }

        .part-l {
            padding-top: 25px;
            padding-left: 20px;

            > li {
                margin-left: 19px;
                padding-bottom: 20px;
                line-height: 1.1em;
                list-style: square;
            }
        }

        .price-wrapper {
            margin-right: 20px;
            margin-left: 20px;
            text-align: right;
            @include media-breakpoint-down(xs) {
                margin-right: 10px;
                margin-left: 10px;
            }
        }

        .price {
            padding-bottom: 10px;

            .p2 {
                @include font(headline-bold-l);
            }
        }

        .button {
            @include media-breakpoint-down(xs) {
                width: 100%;
            }
        }

        .button-selected {
            @include font(copy-bold);
            display: flex;
            align-items: center;
            justify-content: flex-end;
            height: 50px;
            color: $color-green;

            @include media-breakpoint-down(xs) {
                width: 100%;
            }

            &::before {
                @extend [class^='icon-'];
                @extend .icon-checkmark::before;
                display: inline-block;
                width: 25px;
                height: 25px;
                margin-right: 9px;
                padding: 2px 4px 0 0;
                border-radius: 25px;
                background-color: $color-green;
                color: $color-white;
                font-size: 18px;
            }
        }

        .part.has-1 {
            .cta {
                display: flex;
                flex-direction: column;
            }
        }

        @include media-breakpoint-up(sm) {
            .part.has-1 {
                .cta {
                    flex-direction: row;
                    align-items: flex-end;
                    justify-content: flex-end;
                }

                .price {
                    padding-bottom: 0;
                }

                .button,
                .button-selected {
                    width: 160px;
                    margin-left: 40px;
                }

                .button-selected {
                    margin-bottom: -7px;
                }
            }
        }
    }

    .rrt-to .transfer-to-ship {
        margin: 0;
        padding-top: 20px;
    }
}

.transport-option {
    .child-hint {
        display: inline-block;
        margin-bottom: 0;

        a {
            display: inline-block;
            padding-right: 1.5rem;
            text-align: left;

            &::after {
                margin-top: -11px;
                font-size: 1.25rem;
            }
        }
    }

    .train-hint {
        @include font(copy);
        padding-top: 20px;

        > a {
            padding-right: 1.5rem;

            &::after {
                margin-top: -11px;
                font-size: 1.25rem;
            }

            @include media-breakpoint-down(sm) {
                text-align: right;
            }
        }
    }
}

.overlay-travel-info {
    div > a {
        text-decoration: underline;
    }
}

.page-travel .api-step-transporttype .transport-option.is-selected .travel-class-options .radiobutton-element label:hover {
    cursor: pointer;
}

.page-travel .api-step-transporttype .transport-option.is-selected .travel-class-options .radiobutton-element.checked label:hover {
    cursor: default;
}
