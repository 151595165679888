.page-header {
    @include media-breakpoint-down(md) {
        overflow: hidden;
    }
}

.page-header-info-bar {
    height: 60px;
    background-image: linear-gradient(to left, rgba($color-light-blue, 0), $color-light-blue 15%, $color-light-blue 85%, rgba($color-light-blue, 0));
    overflow: hidden;

    @include media-breakpoint-down(md) {
        display: none;
    }

    .link-list {
        display: flex;
        margin-top: px-to-rem(5px);

        > li {
            display: inline-block;
        }

        .button {
            padding-right: 1.25rem;
            padding-left: 0;
        }

        .info-bar-logo {
            display: inline-flex;
            margin-top: .2rem;
            padding-right: 1.25rem;
        }
    }

    .checkmark-list {
        margin-top: px-to-rem(15px);
        margin-left: -12px;

        > li {
            margin-left: px-to-rem(10px);

            &::before {
                padding-right: 5px;
            }
        }
    }
}

.page-header-info-bar-menu-left {
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -25px;

    .wrapper {
        position: absolute;
        top: 12px;
        left: 21px;
        width: 40px;
        height: 30px;

        > span {
            position: absolute;
            left: 0;
            width: 35px;
            height: 4px;
            border-radius: 2px;
            background-color: $color-dark-blue;
        }
    }

    .line-1 {
        top: 0;
    }

    .line-2 {
        top: 9px;
    }

    .line-3 {
        top: 18px;
    }

    .menu {
        position: absolute;
        top: 0;
        background-color: $color-dark-blue;
        overflow-y: auto;
    }
}

.page-header-info-bar-menu-right {
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -25px;

    .phone {
        font-size: 1.15em;
    }
}

.page-header-logo-bar {
    display: inherit;
    position: relative;

    @include media-breakpoint-up(lg) {
        display: none;
    }

    @include media-breakpoint-down(md) {
        height: 80px;
        text-align: center;
    }

    .logo {
        @include font(headline);
        display: inline-block;
        line-height: 0;
    }

    svg {
        margin-top: 18px;
        width: 60vw;
        min-width: 180px;
        max-width: 260px;
    }
}

.page-header-navigation-bar {
    * {
        transform: translate3d(0, 0, 0); // avoid flickering on ios
    }

    .fixed-content {
        position: relative;
        background-color: $color-bg-navigation;
        box-shadow: $default-drop-shadow;
        z-index: z-index(navigation);

        @include when-inside('body.navigation-fixed') {
            position: fixed;
            top: 0;
            right: 0;
            left: 0;
            transform: translateY(0);
            transition: transform .2s ease-in-out;
        }

        @include when-inside('body.navigation-fixed.navigation-collapsed') {
            transform: translateY(0);

            @include media-breakpoint-down(md) {
                transform: translateY(get-map-value(sm, $header-heights) * -1);
            }

            @include media-breakpoint-down(xs) {
                transform: translateY(get-map-value(xs, $header-heights) * -1);
            }
        }
    }
}

.ts-header {
    background: $color-lighter-blue;

    .wrapper {
        display: flex;
        padding: 23px 0 20px 0;
    }

    .ts-info {
        display: flex;
        padding: 0 20px 0 10px;
        font-size: 3em;
    }

    .ts-headline {
        @include font(copy-bold);
        line-height: 1.2rem;
    }

    .ts-text {
        @include font(copy-s);
    }

    .status {
        @include font(copy-bold-xs);
        display: inline-block;
        width: 52px;
        padding: 0 3px 1px 3px;
        text-align: center;

        &-healthy {
            background-color: $color-status-healthy;
            color: $color-white;
        }

        &-warning {
            background-color: $color-status-warning;
            color: $color-black;
        }

        &-error {
            background-color: $color-status-error;
            color: $color-white;
        }
    }

    .status-topic {
        @include font(copy-xs);
        display: inline-block;
        width: 185px;
    }

    .status-reason {
        @include media-breakpoint-down(xs) {
            display: inline-block;
            margin-bottom: 10px;

            &.last {
                margin-bottom: 0;
            }
        }
    }
}
