.bordered-row {

    @include media-breakpoint-up(sm) {
        display: flex;
    }

    > [class^='col-'],
    > [class*=' col-'] {
        padding-top: 20px;
        padding-bottom: 20px;
        border-top: 2px solid $color-semi-primary;

        @include media-breakpoint-up(sm) {
            padding: 0 20px;
            border-top: 0;
            border-left: 2px solid $color-semi-primary;
        }

        &:first-child {
            padding-top: 0;
            border: 0;

            @include media-breakpoint-up(sm) {
                padding-left: 10px;
            }
        }

        &:last-child {
            padding-bottom: 0;

            @include media-breakpoint-up(sm) {
                padding-right: 10px;
            }
        }
    }
}
