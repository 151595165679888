.is-sticky {
    position: relative;
    top: 70px;

    @supports (position: sticky) {
        position: sticky;
    }
}

.booking-status-sidebar {
    @include media-breakpoint-down(md) {
        display: none;
    }

    .cabin-list {
        display: none;
    }

    .edit-link {
        width: 35px;
        text-align: right;
    }

    .trip-text {
        margin-right: 10px;
    }

    .booking-status-image {
        position: relative;
        padding-bottom: 7px;
        overflow: hidden;

        > img {
            width: 100%;
            height: 150px;
        }
    }

    .route-map {
        position: relative;
        width: 80px;
        height: 80px;
        cursor: pointer;
        overflow: hidden;

        .route-map-image {
            width: inherit;
            height: inherit;
        }

        .route-map-image-booking-status {
            height: 80px;
        }

        .enlarge-button {
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -22px;
            margin-left: -22px;
            font-size: 1rem;
        }
    }

    .cabin-wrapper {
        position: relative;
        will-change: transform;

        .arrow {
            position: absolute;
            top: 0;
            right: -20px;
            width: 55px;
            height: 100%;
            -webkit-tap-highlight-color: transparent;
            transform: rotate3d(1, 0, 0, 0deg);
            transition: transform 150ms ease-in-out;
            cursor: pointer;

            &.open {
                transform: rotate3d(1, 0, 0, 180deg);
            }

            &::after {
                @extend [class^='icon-'];
                @extend .icon-drop-down::before;
                display: flex;
                position: absolute;
                top: 0;
                right: px-to-rem(20px);
                align-items: center;
                width: px-to-rem(20px);
                height: 100%;
                font-size: px-to-rem(20px);
                line-height: 1;
                text-align: center;
                pointer-events: none;
            }
        }
    }

    .discount-section {
        margin-bottom: 17px;
        border-bottom: 1px solid $color-semi-primary;
    }
}
