// Colors
////////////////////////////////////////////////////////////////////////////////////////////////

/* stylelint-disable color-hex-case, color-hex-length, color-function-notation, alpha-value-notation, scss/dollar-variable-empty-line-before */
$color-turquoise: #85cee4;
$color-dark-turquoise: #78b7cd;
$color-dark-blue: #263e6a;
$color-light-blue: #ceebf4;
$color-medium-blue: #C7F1FF;
$color-border-payment-dark: rgba(37, 62, 106, .5);
$color-border-payment-light: rgba(37, 62, 106, .2);
$color-lighter-blue: #e7f5fa;
$color-aqua-turquoise: #72cae8;
$color-green: #53bb6f;
$color-error-red: #aa4442;
$color-deep-aqua: #32b0db;
$color-deep-sky-blue: #2692d8;
$color-logo-red: #d51c26;
$color-logo-dark-blue: #002c58;
$color-unavailable: #d41318;
$color-border-insurance-blocked: rgba(37, 62, 106, .5);
$color-alert-red: #DE1E1E;
$color-ssl-icon: #57BC74;
$color-form-border: #929EB4;
$color-border: #5e88a6;
$color-c-nav: #7aadc4;

$color-price-model-1: #9dd8e9;
$color-price-model-2: #b6e2ef;
$color-price-model-3: #b7e6f5;
$color-price-model-4: #bee5f2;
$color-price-model-select-border: #d8e1eb;
$color-deck-mark: #1c93d9;

$color-white: #ffffff;
$color-semi-white: rgba(255, 255, 255, .2);
$color-black: #000000;
$color-semi-black: rgba($color-black, .5);
$color-transparent: transparent;

$color-primary: $color-dark-blue;
$color-semi-primary: rgba($color-dark-blue, .5);
$color-bg: $color-turquoise;
$color-bg-navigation: $color-white;
$color-bg-booking-status: $color-light-blue;

$color-drop-shadow: rgba(0, 0, 0, .2);
$border-drop-shadow: rgba(0, 0, 0, .1);

$color-line-semi-dark-blue: rgba($color-dark-blue, .1);
$color-sticky-cta-dark-blue: rgba($color-dark-blue, .9);
$color-semi-white: rgba($color-white, .8);
$color-semi-primary-disabled: rgba($color-dark-blue, .25);
$color-semi-light-blue-disabled: rgba($color-light-blue, .4);
$color-semi-dark-turquoise: rgba($color-dark-turquoise, .15);

$color-status-healthy: #339900;
$color-status-warning: #ffcc00;
$color-status-error: #cc3300;
/* stylelint-enable color-hex-case, color-hex-length, color-function-notation, alpha-value-notation, scss/dollar-variable-empty-line-before */

// FONTS
////////////////////////////////////////////////////////////////////////////////////////////////
$base-font-size: 20px;
$tui-type-regular: 'SourceSansPro', sans-serif;
$tui-type-bold: 'SourceSansPro-SemiBold', sans-serif;
$fonts: (
    copy: (
        font: 1rem / 1.5rem $tui-type-regular,
    ),
    copy-bold: (
        font: 1rem / 1.5rem $tui-type-bold,
    ),
    copy-m: (
        font: 1.25rem / 1.75rem $tui-type-regular,
    ),
    copy-m-bold: (
        font: 1.25rem / 1.75rem $tui-type-bold,
    ),
    copy-s: (
        font: .9rem / 1.5rem $tui-type-regular,
    ),
    copy-s-has4: (
        font: .7rem / 1rem $tui-type-regular,
    ),
    copy-bold-s: (
        font: .9rem / 1.5rem $tui-type-bold,
    ),
    copy-xs: (
        font: .6rem / 1rem $tui-type-regular,
    ),
    copy-bold-xs: (
        font: .6rem / 1rem $tui-type-bold,
    ),
    copy-xss: (
        font: .5rem / .75rem $tui-type-regular,
    ),
    copy-bold-xss: (
        font: .5rem / .75rem $tui-type-bold,
    ),
    headline-s: (
        font: 1.25rem / 1.5rem $tui-type-regular,
    ),
    headline-bold-s: (
        font: 1.25rem / 1.5rem $tui-type-bold,
    ),
    headline: (
        font: 1.5rem / 2rem $tui-type-regular,
    ),
    headline-bold: (
        font: 1.5rem / 2rem $tui-type-bold,
    ),
    headline-l: (
        font: 2rem / 2rem $tui-type-regular,
    ),
    headline-bold-l: (
        font: 2rem / 2rem $tui-type-bold,
    ),
    button: (
        font: 1rem / 1.2rem $tui-type-regular,
        letter-spacing: .005rem,
        text-decoration: none,
    ),
    button-has4: (
        font: .8rem / 1rem $tui-type-regular,
        letter-spacing: .005rem,
        text-decoration: none,
    ),
    link: (
        font-family: $tui-type-regular,
    ),
    form-element: (
        font: 1rem / 1.1rem $tui-type-regular,
        letter-spacing: .005rem,
    ),
    form-label: (
        font: .9rem / 1rem $tui-type-regular,
        letter-spacing: .005rem,
    ),
    contact: (
        font: 1.2rem / 1.2rem $tui-type-bold,
    ),
    contact-content: (
        font: .8rem / .9rem $tui-type-regular,
    ),
    icon-size-s: (
        font-size: 1.25rem,
    ),
    icon-size: (
        font-size: 1.5625rem,
    ),
);

// Grid
////////////////////////////////////////////////////////////////////////////////////////////////
$grid-gutter-width: 10px;
$grid-gutter-width-sm-up: 20px;
$grid-breakpoints: (
    xx: 0,
    xs: 375px,
    sm: 768px,
    md: 1024px,
    lg: 1200px,
    xl: 1440px
);

// Links
////////////////////////////////////////////////////////////////////////////////////////////////
$link-font-color: $color-primary;
$link-font-color-hover: $color-deep-sky-blue;
$link-text-decoration: none;
$link-text-decoration-hover: none;

// SIZES
////////////////////////////////////////////////////////////////////////////////////////////////
$header-heights: (
    xs: 50px,
    sm: 90px,
    lg-fix: 70px,
    lg: 100px
);
$booking-status-heights: (
    xs: 50px,
    sm: 64px,
    lg: auto,
);

// Buttons
////////////////////////////////////////////////////////////////////////////////////////////////
$button-color-light: $color-white;
$button-color-dark: $color-dark-blue;
$button-color-hover: $color-deep-sky-blue;
$button-color-cta: $color-green;
$button-opacity-disabled: .5;

// Forms
////////////////////////////////////////////////////////////////////////////////////////////////
$form-group-margin: 0 0 1.25rem 0;
$form-element-height: 2.5rem;
$form-element-border-width: 2px;
$form-element-border-radius: 0;
$form-element-padding: 0 .7rem;
$form-element-disabled-opacity: .7;
$form-color-dark: $color-dark-blue;
$form-color-light: $color-white;
$form-color-medium: $color-semi-primary;
$form-color-special: $color-light-blue;

// Decorations
////////////////////////////////////////////////////////////////////////////////////////////////
$default-drop-shadow: 3px 3px 5px 0 $color-drop-shadow;

// Z-INDIZEDS
////////////////////////////////////////////////////////////////////////////////////////////////
$z-indizes: (
    default: 1,
    above: 2,
    sticky-cta: 5,
    contact: 6,
    below: -1,
    booking-status: 10,
    rate-stars: 15,
    navigation: 20,
    highest-content: 99,
    overlay: 100,
    alert: 101,
    spinner: 102,
    top: 1000
);

// PAGE
////////////////////////////////////////////////////////////////////////////////////////////////
$page-padding-top: 60px;
