// Main.scss
////////////////////////////////////////////////////////////////////////////////////////////////

html {
    height: 100%;
    font-size: $base-font-size;
    box-sizing: border-box;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

// Body
////////////////////////////////////////////////////////////////////////////////////////////////
body {
    @include font(copy, $color-primary);
    height: 100%;
    background-color: $color-bg;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &::before {
        display: none;
        content: 'xs';

        @include media-breakpoint-up(xs) {
            content: 'xs';
        }

        @include media-breakpoint-up(sm) {
            content: 'sm';
        }

        @include media-breakpoint-up(lg) {
            content: 'lg';
        }

        @include media-breakpoint-up(xl) {
            content: 'xl';
        }
    }
}

// Links - Anker
////////////////////////////////////////////////////////////////////////////////////////////////
a {
    @include font(link, $link-font-color);
    outline: none;
    text-decoration: $link-text-decoration;

    a:hover,
    a:active,
    &.active {
        color: $link-font-color-hover;
        text-decoration: $link-text-decoration-hover;
    }
}

.underline {
    text-decoration: underline;
}

// Paragraph
////////////////////////////////////////////////////////////////////////////////////////////////
p {
    margin: 0 0 1em 0;
}

// Reset Lists
////////////////////////////////////////////////////////////////////////////////////////////////
ul,
li {
    margin: 0;
    padding: 0;
    list-style: none;
}

ul {
    &.bullet-list {
        margin-left: 20px;
        list-style: disc;

        > li {
            list-style: disc;
        }
    }
}

// Responsive image
////////////////////////////////////////////////////////////////////////////////////////////////
img {
    &.responsive {
        display: block;
        width: 100%;
        height: auto;
    }
}
