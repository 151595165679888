.page-confirm {
    padding-bottom: 0;

    .confirm {
        @media (max-width: map-get($grid-breakpoints, sm)) {
            padding: 0 20px;
        }
        @media (max-width: map-get($grid-breakpoints, xs)) {
            padding: 0 15px;
        }
    }

    .confirm-info {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;

        @media (min-width: map-get($grid-breakpoints, sm)) {
            flex-direction: row;
        }

        .ship-image {
            display: none;
            width: 300px;
            height: 100%;
            margin-right: 0;
            margin-bottom: 10px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            overflow: hidden;

            &.small {
                display: block;
                width: auto;
                height: 35vw;
            }

            @media (min-width: map-get($grid-breakpoints, sm)) {
                display: block;
                margin-right: 20px;
                margin-bottom: 0;

                &.small {
                    display: none;
                }
            }

            @media (min-width: map-get($grid-breakpoints, md)) {
                width: 380px;
            }
        }
    }

    .transaction-number {
        @include font(headline);
        margin: 0;
        padding: px-to-rem(10px);
        background-color: $color-light-blue;
    }

    .wait {
        position: relative;
        padding-right: px-to-rem(60px);

        .loader {
            position: absolute;
            right: px-to-rem(10px);
            width: 2em;
            height: 2em;
            margin-top: -20px;
            border-width: .125em;
        }
    }

    .section-documents {
        padding-top: 20px;

        .headline {
            margin-bottom: 0;
        }

        .documents {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: flex-start;

            .pdf-text {
                height: inherit;
                min-height: 2rem;
                padding: 0 0 0 2px;
                white-space: inherit;
            }
        }
    }

    .trustpilot-content {
        padding: px-to-rem(50px) 0 px-to-rem(60px) 0;
        background-color: $color-light-blue;
    }

    .trustpilot-rate {
        position: relative;
        max-width: 310px;
        min-height: 52px;
        margin: 0 auto px-to-rem(50px) auto;
    }

    .row.is-table {
        display: table;

        > [class*='col-'] {
            display: table-cell;
            float: none;
            vertical-align: top;

            @include media-breakpoint-down(xs) {
                display: inherit;
            }
        }
    }

    .confirm-border {
        padding: 5px;
        background-color: $color-turquoise;

        @media (min-width: map-get($grid-breakpoints, xs)) {
            padding: 10px;
        }

        @media (min-width: map-get($grid-breakpoints, lg)) {
            padding: 28px;
        }
        @media (min-width: map-get($grid-breakpoints, xl)) {
            padding: 50px;
        }
    }

    .content-teaser-inner {
        padding: 10px 10px 0 10px;
        background-color: $color-light-blue;

        @media (min-width: map-get($grid-breakpoints, xs)) {
            padding: 20px 10px 0 10px;
        }

        @media (min-width: map-get($grid-breakpoints, sm)) {
            padding: 20px 18px 0 18px;
        }

        @media (min-width: map-get($grid-breakpoints, lg)) {
            padding: 28px 21px 0 21px;
        }

        @media (min-width: map-get($grid-breakpoints, xl)) {
            max-width: 1390px;
            padding: 40px 44px 0 44px;
        }
    }

    @media (min-width: map-get($grid-breakpoints, sm)) {
        .teaser-row {
            margin-bottom: px-to-rem(80px);
        }
    }

    @media (max-width: map-get($grid-breakpoints, sm)) {
        .teaser-top {
            padding-bottom: 10px;
        }
    }

    .teaser {

        @include media-breakpoint-down(xs) {
            margin-bottom: px-to-rem(30px);
        }

        .teaser-image {
            margin-bottom: px-to-rem(20px);
        }

        .teaser-headline {
            margin-bottom: px-to-rem(10px);

            + .button,
            + .image-button {
                margin-top: px-to-rem(10px);
            }
        }

        .image-button,
        .button {
            position: absolute;
            bottom: -40px;

            @include media-breakpoint-down(xs) {
                position: inherit;
                bottom: inherit;
            }
        }
    }

    .image-button {
        display: inline-block;
        width: 100%;
        max-width: 170px;
        height: $form-element-height;

        > img {
            height: inherit;
        }
    }

    .confirm-premium {
        position: relative;
        background-color: $color-turquoise;

        sup {
            font-size: smaller;
            line-height: .1;
            vertical-align: super;
        }

        &::after,
        &::before {
            display: block;
            position: absolute;
            top: 19px;
            left: 0;
            width: 100%;
            height: 2px;
            background: $color-dark-blue;
            content: '';

            @media (min-width: map-get($grid-breakpoints, sm)) {
                top: 19px;
            }
        }

        &::after {
            top: auto;
            bottom: 0;
        }

        .headline {
            margin-bottom: 0;
            font-size: .8em;
            text-align: center;
            text-transform: uppercase;
            @media (min-width: map-get($grid-breakpoints, xs)) {
                font-size: .95em;
            }
            @media (min-width: map-get($grid-breakpoints, sm)) {
                font-size: 1.1em;
            }

            .top {
                display: inline-block;
                position: relative;
                padding: 0 20px;
                background-color: $color-turquoise;
            }
        }

        .premium {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            padding: 0 30px 30px 30px;

            li {
                flex: 1 1 auto;
                width: 140px;
                max-width: 150px;
                padding: 10px 0;
                text-align: center;

                @media (min-width: map-get($grid-breakpoints, xs)) {
                    width: 150px;
                }

                .icon {
                    display: block;
                    font-size: 3em;
                }

                .text {
                    @include font(contact-content);
                    display: block;
                    padding: 0 10px;
                    font-weight: bold;
                }
            }
        }
    }

    .spacer {
        height: 10px;
        background-color: $color-turquoise;

        @media (min-width: map-get($grid-breakpoints, sm)) {
            height: 28px;
        }

        @media (min-width: map-get($grid-breakpoints, lg)) {
            height: 50px;
        }
    }
}
