.cabin-main {
    .hidden {
        display: none;
    }

    .api-step-headline.alternative {
        margin-bottom: 0;
    }

    .cabin-tab-choice,
    .cabin-count-choice {
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: space-between;

        @include media-breakpoint-down(xs) {
            margin: -10px;
        }

        .cabin-c {
            display: flex;
            position: relative;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 40px;
            margin-top: 10px;
            padding: 0 10px 0 30px;
            border-bottom: 1px solid $color-border;
            background-color: rgba($color-dark-turquoise, .5);
            cursor: pointer;
            -webkit-tap-highlight-color: transparent;

            @include media-breakpoint-down(xs) {
                justify-content: center;
                padding: 0;
                border-bottom: 2px solid $color-border;
                background-color: transparent;
            }
        }

        .only-on-cabin {
            display: none;
        }

        @include media-breakpoint-down(xs) {
            .none-text {
                display: none;
            }
        }

        .is-select {
            @include font(copy-bold);
            min-width: 150px;
            height: 50px;
            margin-top: 0;
            padding: 0 20px 0 30px;
            border-top: 1px solid $color-border;
            border-right: 0 solid $color-border;
            border-bottom: 1px solid $color-turquoise;
            border-left: 0 solid $color-border;
            background-color: $color-turquoise;
            cursor: initial;

            @include media-breakpoint-down(xs) {
                min-width: 96px;
                padding: 0 10px 0 10px;
                border-top: 0 solid $color-border;
                border-bottom: 6px solid $color-border;
                background-color: transparent;

                .text {
                    display: inline-block;
                }
            }
        }

        .is-not-completed {
            color: $color-error-red;
            font-weight: bold;
        }

        .increase-normal {
            display: initial;

            @include media-breakpoint-down(xs) {
                display: none;
            }
        }

        .decrease-small {
            display: none;
            @include media-breakpoint-down(xs) {
                display: inline-block;
                position: absolute;
                top: 70px;
                right: 10px;

                .icon-close-small {
                    position: initial;
                }
            }
        }

        @include media-breakpoint-up(sm) {
            .cabin-c::after {
                position: absolute;
                right: 0;
                width: 1px;
                height: 32px;
                background-color: $color-border;
                content: '';
            }

            .is-select::before {
                position: absolute;
                left: -1px;
                width: 22px;
                height: 50px;
                background-color: transparent;
                background-image: linear-gradient(to bottom, $color-turquoise 0, $color-turquoise 1px), url('../images/links_neu.png');
                background-repeat: no-repeat, no-repeat;
                background-position: -3px top, -1px center;
                background-size: 22px 1px, 22px 50px;
                content: '';
            }

            .is-select::after {
                position: absolute;
                right: -1px;
                width: 22px;
                height: 50px;
                background-color: transparent;
                background-image: linear-gradient(to bottom, $color-turquoise 0, $color-turquoise 1px), url('../images/rechts_neu.png');
                background-repeat: no-repeat, no-repeat;
                background-position: 2px top, right center;
                background-size: 22px 1px, 22px 50px;
                content: '';
            }

            // IE11
            @media all and (-ms-high-contrast: none) {
                .cabin-c::after {
                    top: 2px;
                }

                .is-select::before,
                .is-select::after {
                    display: none;
                }

                .is-select {
                    border-right: 1px solid $color-border;
                    border-left: 1px solid $color-border;
                }
            }

            .cabin-c.is-select:first-child::before {
                left: 0;
                width: 0;
                border-right: 1px solid $color-border;
                background-image: none;
            }

            .cabin-c.is-select:nth-child(5) {
                border-width: 1px 1px 1px 0;
            }

            // IE11
            @media all and (-ms-high-contrast: none) {
                .cabin-c.is-select:nth-child(5) {
                    border-width: 1px 1px 1px 1px;
                }
            }

            .cabin-c.is-select:nth-child(5)::after {
                top: 2px;
                right: -8px;
                width: 10px;
                height: 55px;
                background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAABCAIAAABR8BlyAAAAJUlEQVR4AWOM61jGwMCga6XFyMjIwAgGTEyMKAwolwFOAjWACQB+7AJOTtzS+wAAAABJRU5ErkJggg==');
                background-repeat: repeat-y;
                background-size: 10px 1px;
            }

            .cabin-c:last-child::after {
                background-color: transparent;
            }
        }

        .link {
            cursor: pointer;

            .add-cabin {
                min-width: 192px;
                padding: 0 40px 0 10px;
                line-height: 1em;

                @include media-breakpoint-up(sm) {
                    min-width: unset;
                    padding: 0 30px 0 0;
                }
            }
        }

        .icon-close-small,
        .icon-magnifier-plus {
            // @include font(copy-xs);
            position: absolute;
            top: 4px;
            right: 10px;
            padding: 10px;
            font-size: 13px;
        }

        .icon-close-small {
            top: 9px;
        }

        .is-select > .icon-close-small {
            right: 15px;
        }

        .cabin-c.link {
            justify-content: flex-end;
        }
    }

    .cabin-count-choice {
        padding-top: 10px;
    }

    .cabin-tab-choice {
        .cabin-c {
            justify-content: center;
            padding: 0 10px;
        }

        @include media-breakpoint-up(sm) {
            .cabin-c.is-select:last-child {
                border-width: 1px 1px 1px 0;
            }

            // IE11
            @media all and (-ms-high-contrast: none) {
                .cabin-c.is-select:last-child {
                    border-width: 1px 1px 1px 1px;
                }
            }

            .cabin-c.is-select:last-child::after {
                top: 2px;
                right: -8px;
                width: 10px;
                height: 55px;
                background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAABCAIAAABR8BlyAAAAJUlEQVR4AWOM61jGwMCga6XFyMjIwAgGTEyMKAwolwFOAjWACQB+7AJOTtzS+wAAAABJRU5ErkJggg==');
                background-repeat: repeat-y;
                background-size: 10px 1px;
            }
        }
    }

    .c-navigation {
        @include font(copy-s);
        display: flex;
        position: relative;
        align-items: center;
        justify-content: space-between;
        margin: 20px -30px -5px -30px;
        padding: 19px 30px 0 30px;
        border-top: 2px solid $color-c-nav;

        @include media-breakpoint-down(xs) {
            margin: 20px -10px -5px -10px;
            padding: 19px 10px 0 10px;
        }

        .c-n-left,
        .c-n-right {
            display: flex;
            align-items: center;
            cursor: pointer;
        }

        .icon-small-arrow-right,
        .icon-small-arrow-left {
            font-size: .9em;
        }

        .icon-small-arrow-right {
            padding-left: 10px;
        }

        .icon-small-arrow-left {
            padding-right: 10px;
        }
    }

    .cabin-part {
        padding: 40px 0 10px 0;

        @include media-breakpoint-up(sm) {
            padding: 30px;
            border-right: 1px solid $color-border;
            border-bottom: 1px solid $color-border;
            border-left: 1px solid $color-border;
            box-shadow: 3px 3px 6px 0 $border-drop-shadow;
        }
    }

    .cabin-part-view {
        opacity: 0;
        animation: cabin-in 250ms ease-in-out forwards;
    }

    .party-view {
        padding-top: 1rem;
    }

    @keyframes cabin-in {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    .cabin-price-cta-wrapper {
        padding: 30px 15px 0 8px;
        text-align: right;

        @include media-breakpoint-down(xs) {
            padding: 30px 0 0 0;
        }

        .p1 {
            @include font(copy-bold);
        }

        .p2 {
            @include font(headline-bold-l);
        }

        .cabin-selected {
            @include font(copy-bold);
            display: inline-flex;
            position: relative;
            top: -5px;
            height: 2.12rem;
            margin-left: 60px;
            color: $color-green;

            @include media-breakpoint-down(xs) {
                display: flex;
                top: 0;
                justify-content: flex-end;
                width: 100%;
                margin-top: 16px;
                margin-left: 0;
            }

            &::before {
                @extend [class^='icon-'];
                @extend .icon-checkmark::before;
                display: inline-block;
                width: 25px;
                height: 25px;
                margin-top: 2px;
                margin-right: 9px;
                padding: 2px 4px 0 0;
                border-radius: 25px;
                background-color: $color-green;
                color: $color-white;
                font-size: 18px;
            }
        }

        .cabin-disabled {
            @include font(copy-bold);
            display: inline-flex;
            position: relative;
            top: -5px;
            height: 2.1rem;
            margin-left: 14px;
            color: $color-unavailable;

            &::before {
                @extend [class^='icon-'];
                @extend .icon-ic-x::before;
                display: inline-block;
                width: 26px;
                height: 26px;
                margin-top: 2px;
                margin-right: 9px;
                padding: 5px 5px 0 0;
                border-radius: 26px;
                background-color: $color-unavailable;
                color: $color-white;
                font-size: 16px;
                font-weight: bold;
            }
        }

        .cabin-capacity {
            @include font(copy-bold);
            display: inline-flex;
            position: relative;
            top: -5px;
            height: 2.1rem;
            margin-left: 14px;
            color: $color-unavailable;
        }

        .hint-box {
            display: inline-block;
            position: relative;
            top: -6px;
            width: initial;
            margin-left: 14px;

            @include media-breakpoint-down(xs) {
                display: flex;
                top: 0;
                width: 100%;
                margin-top: 16px;
                margin-left: 0;
            }
        }

        .cabin-available {
            position: relative;
            top: -10px;
            margin-left: 14px;

            @include media-breakpoint-down(xs) {
                display: flex;
                top: 0;
                width: 100%;
                margin-top: 16px;
                margin-left: 0;
            }
        }
    }

    .api-running-info {
        height: 400px;
        margin-bottom: 80vh;
        transition: opacity 200ms ease-in-out;

        .in {
            opacity: 1;
        }

        .spinner-container {
            background-color: transparent;
        }

        .wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: inherit;

            .wrapper-loader {
                position: relative;

                .loader {
                    position: unset;
                    top: unset;
                    margin-top: 0;
                    margin-bottom: 5px;
                    font-size: 14px;
                }

                .icon-cabin-type {
                    position: absolute;
                    top: 3px;
                    left: 2px;
                    color: $color-dark-blue;
                    font-size: 50px;
                }
            }
        }
    }

    .api-running {
        opacity: 1;

        &.out {
            opacity: 0;
        }
    }

    .expand {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;

        .cabin-is-expand {
            @include font(copy);
            padding-top: 3px;
            text-align: right;
            cursor: pointer;

            .icon-checkmark,
            .icon-ic-edit {
                padding-left: 10px;
            }
        }
    }

    .arrow {
        position: absolute;
        top: 0;
        right: -10px;
        width: 45px;
        height: 100%;
        transform: rotate3d(1, 0, 0, 0deg);
        transition: transform 150ms ease-in-out;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;
        z-index: 1; // fix safari-css-transform-3d-rotation-glitch

        &.open {
            transform: rotate3d(1, 0, 0, 180deg);
        }

        &::after {
            @extend [class^='icon-'];
            @extend .icon-drop-down::before;
            display: flex;
            position: absolute;
            top: 0;
            right: 15px;
            align-items: center;
            width: px-to-rem(20px);
            height: 100%;
            font-size: px-to-rem(20px);
            line-height: 1;
            text-align: center;
            pointer-events: none;
        }
    }
}
