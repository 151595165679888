.booking-status {
    @include font(copy-s);
    background-color: $color-bg-booking-status;
    box-shadow: $default-drop-shadow;
    overflow: hidden;
    z-index: z-index(booking-status);

    .booking-status-section-headline {
        @include font(copy-bold);
        line-height: 1.2rem;
    }

    .booking-status-section {
        position: relative;
        margin: 0 30px;
        padding: 15px 0;
        border-top: 1px solid $color-semi-primary;

        @include media-breakpoint-down(md) {
            margin: 0 20px;
        }

        &:first-of-type {
            border: 0;
        }

        > p {
            margin: 0;
            line-height: 1.1;

            /* stylelint-disable-next-line scss/selector-no-redundant-nesting-selector */
            & + p {
                margin-top: 5px;
            }
        }
    }

    .booking-status-travel-text {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
    }

    .booking-status-price-section {
        margin: 0 13px 13px 13px;
        padding: 17px;
        background-color: $color-semi-dark-turquoise;

        @include media-breakpoint-down(xs) {
            margin: 0 10px 10px 10px;
            padding: 10px 10px 20px 10px;
        }
    }

    .booking-hint {
        display: flex;
        margin-top: 10px;
        padding-top: 14px;
        border-top: 1px solid $color-semi-primary;
        line-height: 1.1rem;

        .icon-thumb {
            display: flex;
            align-items: center;
            min-width: 40px;
            margin-right: -5px;
            font-size: 2rem;

            &::before {
                position: relative;
                right: -5px;
            }
        }
    }

    .value-right {
        text-align: right;
    }

    .cabin-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        line-height: 1.3;

        .discount {
            font-size: .8rem;
        }

        .cabin-arrow {
            margin-right: 35px;
            margin-left: 5px;
        }

        .total-value {
            @include font(copy-bold);
            line-height: 1.1rem;
        }
    }

    .cabin-list {
        padding-top: 10px;
    }

    .singel-price {
        padding-bottom: 17px;
        line-height: 1rem;

        .cabin-wrapper {
            align-items: normal;
        }
    }

    .b-c-part {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .price-per-person {
        display: flex;
        margin-bottom: px-to-rem(5px);

        &:nth-child(n + 2) {
            margin-top: px-to-rem(5px);
        }

        .price-base,
        .price-discount {
            @include font(copy-s);
        }

        .price-total,
        .price-base,
        .price-discount {
            line-height: 1.1rem;
        }

        .value {
            text-align: right;
            white-space: nowrap;
        }

        .total-value {
            @include font(copy-bold);
            line-height: 1.1rem;
        }

        .discount {
            font-size: .8rem;
        }

        .part-price {
            display: flex;
            justify-content: flex-end;
        }

        .spacer-status-icon {
            width: 35px;
            min-width: 35px;
        }
    }

    .total-price {
        @include font(copy-s);
        padding: 10px 0 0 0;
        text-align: right;

        .price {
            @include font(headline-bold-l);
            margin-left: 5px;
        }

        .notification {
            line-height: 1.1em;
        }
    }

    .cancel-hint {
        padding-top: 10px;
        font-size: .85rem;
        line-height: 1.2em;
        text-align: left;
    }

    .booking-status-section-premium {
        margin-top: 10px;
        padding-top: 10px;
        border-top: 1px solid $color-semi-primary;

        sup {
            font-size: smaller;
            line-height: .1;
            vertical-align: super;
        }

        @include media-breakpoint-down(xs) {
            margin: 10px 0 0 0;

            .booking-status-section-headline {
                line-height: 1rem;
            }
        }
    }

    .premium-wrapper {
        position: relative;
        will-change: transform;

        .arrow {
            position: absolute;
            top: 0;
            right: -18px;
            width: 55px;
            height: 100%;
            -webkit-tap-highlight-color: transparent;
            transform: rotate3d(1, 0, 0, 0deg);
            transition: transform 150ms ease-in-out;
            cursor: pointer;

            &.open {
                transform: rotate3d(1, 0, 0, 180deg);
            }

            &::after {
                @extend [class^='icon-'];
                @extend .icon-drop-down::before;
                display: flex;
                position: absolute;
                top: 0;
                right: px-to-rem(20px);
                align-items: center;
                width: px-to-rem(20px);
                height: 100%;
                font-size: px-to-rem(20px);
                line-height: 1;
                text-align: center;
                pointer-events: none;
            }
        }
    }

    .premium-headline {
        @include font(copy-bold-s);
        line-height: 1.2rem;
    }

    .premium-list {
        display: none;
        margin-top: 20px;
        margin-left: -5px;
        color: $color-dark-blue;
        line-height: .8rem;

        > li {
            position: relative;
        }

        .icon {
            position: absolute;
            top: 7px;
            left: 5px;
            font-size: 1.2rem;

            @media print {
                display: initial;
            }
        }

        .text {
            display: block;
            position: relative;
            top: 0;
            left: 0;
            padding-left: 46px;
            line-height: 36px;
        }
    }

    .status-print {
        margin-top: -13px;
        padding-right: 10px;
        text-align: right;

        @include media-breakpoint-down(xs) {
            margin-top: 0;
        }

        .icon-print {
            margin: 0 0 0 .5rem;
        }
    }
}
