.page-payment {
    .success-box {
        margin-top: px-to-rem(20px);
        margin-bottom: px-to-rem(45px);
        padding: px-to-rem(18px) px-to-rem(30px) px-to-rem(23px) px-to-rem(30px);
        background-color: $color-bg-booking-status;

        @include media-breakpoint-down(xs) {
            margin-top: 0;
            padding: px-to-rem(18px) px-to-rem(15px) px-to-rem(23px) px-to-rem(15px);
        }

        .headline {
            @include font(copy-m-bold);
            margin-bottom: 0;
        }

        .text {
            @include font(copy-m);
        }

        .icon-checkmark {
            padding-left: px-to-rem(10px);
            color: $color-green;
        }
    }

    .booking-page-headline {
        margin-bottom: px-to-rem(10px);
    }

    .payment-info-text {
        margin-bottom: px-to-rem(10px);
    }

    .booking-page-section {
        padding-bottom: 0;
        border-bottom: 0;
    }

    .sepa-wrapper,
    .credit-card-wrapper {
        padding: px-to-rem(30px);
        background-color: $color-white;
    }

    .headline {
        @include font(headline);
        position: relative;
    }

    .invoice-name {
        @include font(headline);
        position: relative;
        padding-bottom: px-to-rem(20px);
    }

    .success-text {
        @include font(headline);
        position: relative;
        padding-top: px-to-rem(20px);
        padding-bottom: px-to-rem(20px);
    }

    .error-text {
        @include font(form-label, $color-error-red);
        padding-top: px-to-rem(20px);
        padding-bottom: px-to-rem(20px);
    }

    .credit-card-wrapper {
        .invoice-name {
            padding-bottom: 0;
        }
    }

    .overlay-info-icon {
        position: absolute;
        top: 10px;
    }

    .overlay-iban-info-icon {
        position: relative;
        top: 43px;
        width: 38px;
        height: 38px;
    }

    .iframe-wrapper {
        position: relative;
    }

    .papagena-iframe-style {
        width: 100%;
        height: 620px;
        background-color: $color-white;

        @include media-breakpoint-down(sm) {
            height: 700px;
        }
    }

    .info {
        margin-top: px-to-rem(10px);
        padding-top: px-to-rem(40px);
        padding-bottom: px-to-rem(40px);
        border-top: 2px solid $color-semi-primary;

        &.clean {
            padding-bottom: 0;
            border: 0;
        }

        &.top-zero {
            padding-top: 0;
        }

        &.border-top {
            border-top: 1px solid $color-primary;
            border-bottom: 0;
        }

        &.margin-top {
            margin-top: px-to-rem(20px);
        }

        > a {
            text-decoration: underline;
            cursor: pointer;
        }

        &.price-model {
            padding-top: px-to-rem(20px);
            padding-bottom: px-to-rem(30px);
        }

        > .title {
            padding-bottom: px-to-rem(10px);
        }
    }

    .change {
        padding: px-to-rem(20px) 0;
        text-align: right;
    }

    .hidden {
        display: none;
    }

    .hint.help-hint > a {
        text-align: right;
    }

    .ssl-hint {
        padding: 40px 80px 0 0;
        background-color: $color-white;

        &::after {
            top: 36px;
            right: 0;
            left: initial;
            color: initial;
        }
    }
}

.payment-change-view {
    padding: 30px;
    background: $color-white;
    color: $color-dark-blue;
    overflow: auto;

    .payment-type-heading {
        font-weight: 700;
        line-height: 20px;
        margin-bottom: 16px;
    }

    .payment-hr {
        height: 1px;
        background: #d9d9d9;
        width: 100%;
        margin-top: 32px;
        margin-bottom: 32px;
    }

    .payment-type-note {
        margin-top: -20px;
        margin-bottom: 20px;
    }

    .content {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .item {
        margin-bottom: unset;

        .inner-item,
        #sepa-acknowledged,
        #sepa-error-wrapper {
            display: flex;
            align-items: center;
            position: relative;
            width: 100%;
            height: 74px;
            border: 1px solid $color-border-payment-dark;
            gap: 16px;
            padding-left: 16px;
            padding-right: 16px;

            &.credit-card {
                border: 1px solid $color-border-payment-light;
            }

            &:hover {
                cursor: pointer;
            }

            &.active:hover {
                cursor: default;
            }

            &.active {
                background-color: $color-medium-blue;
            }
        }

        #sepa-acknowledged-wrapper,
        #sepa-error-wrapper {
            display: none;

            &.visible {
                display: flex;
            }

            #sepa-acknowledged,
            &#sepa-error-wrapper {
                border-top: none;
                height: unset;
                white-space: normal;
                padding: 12px 16px 12px 16px;
                gap: 16px;
                flex-direction: column;
                align-items: start;
                line-height: 26px;
                font-size: 18px;

                .sepa-acknowledged-heading {
                    font-weight: 700;
                }

                .sepa-error-header {
                    @include font(form-label, $color-error-red);
                    font-weight: 700;
                }

                label::before {
                    top: 0;
                    margin: 5px 0 0;
                }
            }
        }
    }

    .radiobutton-element {
        width: 32px;
        height: 32px;
    }

    .text {
        @include font(copy-bold);
        flex: 1;
        line-height: 1em;
        white-space: pre-wrap;
        word-wrap: break-word;
    }

    .payment-logo-wrapper {
        width: 100px;
        height: 50px;
    }

    .payment-logo-icon {
        width: 100px;
        height: 50px;
    }

    .png-logo {
        width: 100px;
        height: 50px;
    }

    .button-wrapper {
        padding-top: px-to-rem(20px);
        text-align: right;
    }

    .form-group:last-of-type {
        margin-bottom: unset;
    }

    .form-group.has-errors .form-error {
        flex: none;
        width: 100%;
    }

    .cancel-hint-payment {
        @include font(copy-bold);
        position: relative;
        margin: 0 0 0 25px;
        padding: 0 0 6px 0;
        color: $color-green;

        &::before {
            @extend [class^='icon-'];
            @extend .icon-checkmark::before;
            position: absolute;
            top: 4px;
            left: -25px;
            width: 1.4em;
        }
    }
}

.overlay-payment-iban {
    .iban-img {
        width: 100%;
        max-width: 500px;
        height: 100%;
        max-height: 260px;
        margin: 0 auto;
    }
}

.sepa-wrapper {
    .iban-hint-under-xs {
        display: none;

        @media (max-width: map-get($grid-breakpoints, xs)) {
            display: block;
            position: relative;

            .overlay-iban-info-icon {
                display: inline;
                position: absolute;
                top: 0;
                right: px-to-rem(7px);
                z-index: z-index(above);
            }

            .help-hint {
                > a::after {
                    font-size: 1.2rem;
                }
            }
        }
    }

    .iban-hint-over-xs {
        display: inherit;

        @media (max-width: map-get($grid-breakpoints, xs)) {
            display: none;
        }
    }

    .iban-api-hint {
        @include font(copy-s);
        display: none;
        margin-top: px-to-rem(-10px);
        margin-bottom: px-to-rem(15px);
    }
}
