.cabin-view {
    padding-top: 1rem;

    .cabin-list {
        @include font(copy);
    }

    .cabin {
        margin-bottom: 20px;
        padding: 15px;
        transition: transform 125ms ease-in-out, opacity 200ms ease-in-out;
        background-color: $color-light-blue;
        opacity: 1;
        overflow: hidden;
        visibility: visible;

        &.selected {
            height: initial;
            background-color: $color-white;
            visibility: visible;
        }

        .content-wrapper {
            display: flex;

            @include media-breakpoint-down(xs) {
                display: block;
            }

            .nobr {
                white-space: pre-wrap;
            }
        }

        .cabin-headline {
            position: relative;
        }
    }

    // .wrapper-is-single {
    //     .cabin {
    //         margin-bottom: 0;
    //     }
    //  }

    .cabin-images {
        width: 320px;
        min-width: 320px;
        margin-right: 40px;

        @include media-breakpoint-down(xs) {
            width: auto;
            margin-right: initial;
        }
    }

    .cabin-images-small {
        margin-right: 40px;

        .small-img {
            width: 320px;
            height: 111px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }

        @include media-breakpoint-down(xs) {
            margin-right: initial;
            text-align: center;

            .small-img {
                width: 100%;
            }
        }
    }

    .ct-inter {
        display: flex;
        flex-wrap: wrap;
        padding-left: 20px;

        > li {
            flex: 1 1 auto;
            width: 50%;
            padding-right: 20px;
            list-style: square;
        }
    }

    .cabin-content {
        width: 100%;

        @include media-breakpoint-down(xs) {
            padding-top: 20px;
            padding-left: 5px;
        }

        .checkmark-list {
            @include font(copy);
            display: block;
            padding: unset;
            border: 0;
        }
    }

    .cabin-headline {
        @include font(headline);
        margin-bottom: 14px;
    }

    .cabin-info {
        margin-bottom: px-to-rem(16px);

        [class^='icon-'],
        [class*=' icon-'] {
            font-size: 1.5em;
            line-height: 1;
            vertical-align: middle;
        }
    }

    .cabin-info-item {
        display: inline-block;
        position: relative;
        margin-right: 10px;
        line-height: 1;
        white-space: nowrap;

        .icon {
            position: relative;
            top: -3px;
        }

        .icon-insurance-single {
            margin-left: 6px;
        }
    }

    .cabin-info-item-pipe {
        position: absolute;
        width: 1px;
        height: 22px;
        margin-top: 4px;
        background-color: $color-semi-primary;
    }
}
