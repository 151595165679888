.alert-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color-primary, .8);
    z-index: z-index(alert);
}

.alert {
    display: flex;
    position: relative;
    top: 30%;
    flex-direction: column;
    justify-content: space-between;
    width: 90%;
    max-width: 420px;
    min-height: 200px;
    margin: 0 auto;
    padding: 20px 10px 30px 10px;
    background: $color-white;

    @include media-breakpoint-up(xs) {
        padding: 20px 30px 30px 30px;
    }

    &.confirm {
        max-width: 575px;
    }

    .alert-close {
        position: absolute;
        top: 30px;
        right: 15px;
        cursor: pointer;

        @include media-breakpoint-up(xs) {
            right: 30px;
        }

        &::before {
            @extend [class^='icon-'];
            @extend .icon-close-small::before;
        }
    }

    .alert-headline {
        @include font(headline);
        margin-right: px-to-rem(30px);
        margin-bottom: px-to-rem(20px);

        @include media-breakpoint-up(sm) {
            margin-right: px-to-rem(60px);
        }
    }

    .alert-buttons {
        display: flex;
        justify-content: space-between;

        &.center {
            display: inline;
            text-align: center;
        }

        &.right {
            display: inline;
            text-align: right;
        }
    }
}
