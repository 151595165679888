//
// Swiper 3.3.1
// Most modern mobile touch slider and framework with hardware accelerated transitions
//
// http://www.idangero.us/swiper/
//
// Copyright 2016, Vladimir Kharlampidi
// The iDangero.us
// http://www.idangero.us/
//
// Licensed under MIT
//
// Released on: February 7, 2016
//
.swiper-container {
    position: relative;
    margin: 0 auto;
    overflow: hidden;
    // Fix of Webkit flickering
    z-index: 1;

    // a11y
    .swiper-notification {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        z-index: -1000;
        pointer-events: none;
    }
}

.swiper-container-no-flexbox .swiper-slide {
    float: left;
}

.swiper-container-vertical > .swiper-wrapper {
    flex-direction: column;
    box-orient: vertical;
}

.swiper-wrapper {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    transition-property: transform;
    z-index: 1;
    box-sizing: content-box;
}

.swiper-container-android .swiper-slide,
.swiper-wrapper {
    transform: translate3d(0, 0, 0);
}

.swiper-container-multirow > .swiper-wrapper {
    flex-wrap: wrap;
    box-lines: multiple;
}

.swiper-container-free-mode > .swiper-wrapper {
    margin: 0 auto;
    transition-timing-function: ease-out;
}

.swiper-slide {
    position: relative;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
}

// Auto Height
.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
    height: auto;
}

.swiper-container-autoheight .swiper-wrapper {
    align-items: flex-start;
    transition-property: transform, height;
}


// IE10 Windows Phone 8 Fixes
.swiper-wp8-horizontal {
    touch-action: pan-y;
}

.swiper-wp8-vertical {
    touch-action: pan-x;
}

// Arrows
.swiper-button-prev,
.swiper-button-next {
    position: absolute;
    top: 50%;
    margin-top: -0.5em;
    background: $color-transparent;
    font-size: 52px;
    cursor: pointer;
    z-index: 3;
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
    cursor: auto;
    opacity: 0.35;
    pointer-events: unset;
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
    right: auto;
    left: 0;
    color: $color-primary;

    &::before {
        @extend [class^='icon-'];
        @extend .icon-arrow-left::before;
    }
}

.swiper-button-prev.swiper-button-black,
.swiper-container-rtl .swiper-button-next.swiper-button-black {
    color: $color-black;
}

.swiper-button-prev.swiper-button-white,
.swiper-container-rtl .swiper-button-next.swiper-button-white {
    color: $color-white;
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
    right: 0;
    left: auto;
    color: $color-primary;

    &::before {
        @extend [class^='icon-'];
        @extend .icon-arrow-right::before;
    }
}

.swiper-button-next.swiper-button-black,
.swiper-container-rtl .swiper-button-prev.swiper-button-black {
    color: $color-black;
}

.swiper-button-next.swiper-button-white,
.swiper-container-rtl .swiper-button-prev.swiper-button-white {
    color: $color-white;
}

// Pagination Styles
.swiper-pagination {
    position: absolute;
    transform: translate3d(0, 0, 0);
    transition: 300ms;
    text-align: center;
    z-index: 1;

    &.swiper-pagination-hidden {
        opacity: 0;
    }
}

// Common Styles
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 10px;
    left: 0;
    width: 100%;
}

// Bullets
.swiper-pagination-bullet {
    display: inline-block;
    width: 13px;
    height: 13px;
    margin: 0;
    padding: 0;
    border: 0;
    border-radius: 100%;
    background: $color-primary;
    box-shadow: none;
    opacity: 0.2;
    appearance: none;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
    cursor: pointer;
}

.swiper-pagination-white .swiper-pagination-bullet {
    background: $color-white;
}

.swiper-pagination-bullet-active {
    background: $color-primary;
    opacity: 1;
}

.swiper-pagination-white .swiper-pagination-bullet-active {
    background: $color-white;
}

.swiper-pagination-black .swiper-pagination-bullet-active {
    background: $color-black;
}

.swiper-container-vertical > .swiper-pagination-bullets {
    top: 50%;
    right: 10px;
    transform: translate3d(0, -50%, 0);

    .swiper-pagination-bullet {
        display: block;
        margin: 5px 0;
    }
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 5px;
}

// Progress
.swiper-pagination-progress {
    position: absolute;
    background: rgba($color-black, .25);

    &.swiper-pagination-white {
        background: $color-semi-black;

        .swiper-pagination-progressbar {
            background: $color-white;
        }
    }

    &.swiper-pagination-black .swiper-pagination-progressbar {
        background: $color-black;
    }

    .swiper-pagination-progressbar {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform: scale(0);
        transform-origin: left top;
        background: lighten($color-black, 10%);
    }
}

.swiper-container-rtl .swiper-pagination-progress .swiper-pagination-progressbar {
    transform-origin: right top;
}

.swiper-container-horizontal > .swiper-pagination-progress {
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
}

.swiper-container-vertical > .swiper-pagination-progress {
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
}
