.service-info {
    margin: 40px 30px;

    @include media-breakpoint-down(md) {
        display: none;
    }

    .service-headline {
        @include font(copy-bold);
    }

    .service-description {
        @include font(form-label);
        margin-top: 10px;
        margin-bottom: 10px;

        &.spacer {
            padding-top: 20px;
        }
    }

    .service-details {
        @include font(form-label);
        margin-bottom: 20px;
    }
}

.service-info-action {
    @include font(headline);
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    text-decoration: none;
    white-space: nowrap;

    &[href^='tel:'] {
        &::before {
            @extend [class^='icon-'];
            @extend .icon-telephone::before;
            margin-right: 5px;
        }
    }

    &[href^='mailto:'] {
        &::before {
            @extend [class^='icon-'];
            @extend .icon-email::before;
            margin-right: 5px;
        }
    }
}
