.insurance-selector {
    $tab-height: 96px;

    .insurance-split-line {
        margin-bottom: px-to-rem(10px);
        padding-bottom: 1em;
        border-bottom: 2px solid $color-semi-primary;
    }

    .insurance-selector-tabview {
        position: relative;

        @include media-breakpoint-up(sm) {
            margin-top: $tab-height;
        }
    }

    .insurance-selector-tabelement {
        display: block;
        margin-bottom: 1rem;

        &.is-selected {
            .insurance-selector-tab {
                background: $color-white;
            }

            .insurance-selector-tabcontent {
                display: block;
            }
        }
    }

    .insurance-selector-tab {
        padding: .5rem;
        background: $color-light-blue;

        @include media-breakpoint-up(sm) {
            display: flex;
            position: absolute;
            top: -$tab-height;
            left: 0;
            align-items: center;
            width: 48%;
            height: $tab-height;

            &.is-last {
                right: 0;
                left: auto;
            }
        }

        .radiobutton-element {
            width: 100%;
        }

        label {
            width: 100%;
            margin-left: 0;
            padding: 0 0 0 47px;

            @include media-breakpoint-up(sm) {
                margin-left: 16px;
                padding: 30px 10px 30px 40px;
            }
        }
    }

    .insurance-selector-tabcontent {
        display: none;
        min-height: 200px;
        padding: .5rem;
        background: $color-white;

        @include media-breakpoint-up(sm) {
            padding: 1.5rem;
        }
    }

    .api-step-insurancetermsandconditions {
        label > a {
            text-decoration: underline;
        }
    }

    .number-input-element {
        .form-element[readonly] {
            border-color: $form-color-medium;
            color: $form-color-dark;
        }
    }
}
