body {
    &.booking-status-bar-open.navigation-fixed {
        overflow: hidden;
    }
}

.booking-status-bar {
    $booking-status-bar-height: 50px;

    .booking-status-image {
        display: none;
    }

    .booking-status {
        position: absolute;
        top: 0;
        bottom: auto;
        width: 100%;
        height: $booking-status-bar-height + 25px;
        background-color: transparent;
        box-shadow: none;

        @include media-breakpoint-up(lg) {
            display: none;
        }

        @include when-inside('body.navigation-fixed') {
            position: fixed;
            top: 0;
            transform: translateY(0);
            -webkit-overflow-scrolling: touch;

            @include media-breakpoint-down(md) {
                transform: translateY(get-map-value(sm, $header-heights));
            }

            @include media-breakpoint-down(xs) {
                transform: translateY(get-map-value(xs, $header-heights));
            }
        }

        @include when-inside('body.booking-status-bar-animated') {
            transition: transform .2s ease-in-out, bottom .2s ease-in-out;
        }

        @include when-inside('body.navigation-collapsed') {
            transform: translateY(0);
        }

        @include when-inside('body.booking-status-bar-open') {
            bottom: 0;
            height: auto;
            background-color: $color-bg-booking-status;
            box-shadow: $default-drop-shadow;
            overflow-y: auto;
        }

        @include when-inside('body.booking-status-bar-open.navigation-fixed') {
            height: auto;

            @include media-breakpoint-down(md) {
                bottom: get-map-value(sm, $header-heights);
            }

            @include media-breakpoint-down(xs) {
                bottom: get-map-value(xs, $header-heights);
            }
        }

        @include when-inside('body.booking-status-bar-open.navigation-fixed.navigation-collapsed') {
            bottom: 0;
        }

        /* stylelint-disable scss/selector-no-redundant-nesting-selector */
        &,
        & * {
            transform: translate3d(0, 0, 0);
        }

        /* stylelint-enable scss/selector-no-redundant-nesting-selector */

        .route-map {
            position: relative;
            width: 100%;
            max-width: 250px;
            cursor: pointer;

            @include media-breakpoint-down(xs) {
                max-width: 45%;
            }

            .route-map-image {
                width: inherit;
                height: inherit;
            }

            .route-map-image-booking-status {
                height: 80px;
            }

            .enlarge-button {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 36px;
                height: 36px;
                margin-top: -18px;
                margin-left: -18px;
            }
        }
    }

    .booking-status-summary {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: $booking-status-bar-height;
        padding: 0 20px;
        background-color: $color-bg-booking-status;
        box-shadow: $default-drop-shadow;

        @include when-inside('body.booking-status-bar-open') {
            box-shadow: $default-drop-shadow;
        }

        @include media-breakpoint-down(xs) {
            padding: 0 10px;
        }

        .summary-trip-name {
            @include ellipsis();
            width: 100%;
        }

        .summary-total-price {
            @include font(copy-bold);
            padding-left: 10px;
            white-space: nowrap;
        }
    }

    .summary-opener {
        display: inline-block;
        position: relative;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        background-color: $color-transparent;
        text-align: center;
        cursor: pointer;
        overflow: hidden;

        svg {
            position: relative;
            top: -4px;
            overflow: visible;
            filter: drop-shadow(0 2px 3px $color-drop-shadow);
            fill: $color-light-blue;
        }

        .icon-drop-up,
        .icon-drop-down {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            font-size: .75rem;
        }

        .icon-drop-up {
            display: none;
        }

        @include when-inside('body.booking-status-bar-open') {
            .icon-drop-up {
                display: inline;
            }

            .icon-drop-down {
                display: none;
            }
        }
    }

    .booking-status-close {
        padding: 30px 0;
        text-align: center;
    }
}
