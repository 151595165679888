.cabin-vip-view {
    @include font(copy-s);
    margin-top: 40px;

    @include media-breakpoint-down(sm) {
        margin-top: 30px;
    }

    .cabin-vip-selected {
        position: relative;
        padding-right: 40px;
    }

    .inner-cc {
        padding: 20px 15px 20px 20px;
        background-color: $color-light-blue;

        &.none {
            transition: none;
        }

        &.hidden-anim {
            padding: 0;
        }

        &.selected {
            background-color: $color-white;
        }
    }

    .no-vip {
        @include font(copy-bold);
        padding-bottom: 20px;
        color: $color-unavailable;
    }

    .vip {
        position: relative;
    }

    .keep {
        padding: 27px 0 7px 0;
        border-top: 1px solid $color-dark-turquoise;
    }

    .radiobutton-element label {
        line-height: 1rem;
    }

    .checkmark-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 20px 5px;

        @include media-breakpoint-down(xs) {
            flex-direction: column;
        }

        > li {
            flex: 1 1 auto;
            width: 50%;
            padding-bottom: 15px;
            line-height: 1.125rem;

            @include media-breakpoint-down(xs) {
                width: 100%;
            }
        }

        .bold > i {
            font-weight: bold;
        }
    }

    .inner-cc .cabin-price-cta-wrapper {
        padding: 10px 5px 0 8px;
    }

    .line {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 5px 5px 27px 0;

        &.no-vip-spacer {
            padding: 5px 5px 5px 0;
        }

        .extern {
            > a {
                text-decoration: underline;
            }
        }

        @include media-breakpoint-down(xs) {
            flex-direction: column-reverse;
            align-items: flex-start;

            .vip-hint-wrapper {
                align-self: flex-end;
                padding: 15px 0 0 0;
            }
        }

        .hint {
            margin-bottom: 0;
        }
    }
}
