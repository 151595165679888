.booking-page {
    .booking-page-headline {
        @include font(headline-l);
        margin-bottom: px-to-rem(25px);
    }
}

.booking-page-section {
    padding-top: px-to-rem(20px);
    padding-bottom: px-to-rem(10px);
    border-bottom: 2px solid $color-semi-primary;

    &.without-border {
        border: 0;
    }
}
