.form-group {
    margin-bottom: px-to-rem(20px);
    font-size: 0;

    > label {
        @include font(form-label, $form-color-dark);
        display: inline-block;
        max-width: 100%;
        margin-bottom: px-to-rem(10px);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        vertical-align: middle;

        @include when-inside('.form-group.has-errors') {
            /* stylelint-disable scss/selector-no-redundant-nesting-selector */
            &,
            & a {
                color: $color-error-red;
            }
            /* stylelint-enable scss/selector-no-redundant-nesting-selector */
        }
    }

    .typeahead-results {
        @include font(form-label, $form-color-dark);
        position: absolute;

        // this is necessary due to absolute position and some 5px right & left padding from col-sm-6
        width: calc(100% - 10px);
        margin-top: -$form-element-border-width;
        border-width: $form-element-border-width;
        border-style: solid;
        border-color: $form-color-dark;
        background-color: $color-lighter-blue;
        box-shadow: $default-drop-shadow;
        z-index: z-index(default);
    }

    .typeahead-result {
        padding: .8rem;

        &:hover {
            background-color: $color-deep-sky-blue;
            color: $color-white;
        }
    }

    .form-error {
        @include font(form-label, $color-error-red);
        display: none;
        margin: 0;
        padding: 0;
    }

    &.disabled {
        > label {
            color: $form-color-medium;
        }
    }

    &.has-errors {
        > label {
            color: $color-error-red;
        }

        .form-error {
            display: block;
            margin: px-to-rem(15px) 0;
        }
    }
}

.form-element {
    @include font(form-element, $form-color-dark);
    display: inline-block;
    width: 100%;
    padding: $form-element-padding;
    padding-top: px-to-rem(14px);
    border-width: $form-element-border-width;
    border-style: solid;
    border-radius: $form-element-border-radius;
    border-color: $form-color-medium;
    background-color: $form-color-light;
    background-image: none;
    vertical-align: middle;

    // CSS specific to iOS devices
    @supports (-webkit-overflow-scrolling: touch) {
        background-clip: padding-box;
    }

    @include when-inside('.form-group.has-errors') {
        border-color: $color-error-red;
        color: $color-error-red;
    }

    &:not(textarea) {
        height: $form-element-height;
        padding-top: 0;
    }

    &[type='number'],
    &[type='number']:hover,
    &[type='number']:focus {
        appearance: textfield;
    }

    &[type='number'],
    &[type='date'] {
        &,
        &:hover,
        &:focus {
            appearance: textfield;
        }

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            appearance: none;
        }
    }

    &:invalid {
        box-shadow: none;
    }

    &::placeholder {
        color: $form-color-medium;
        opacity: 1;

        @include when-inside('.form-group.has-errors') {
            color: $color-error-red;
        }
    }

    &[readonly],
    &:disabled {
        border-color: $form-color-medium;

        @include when-inside('.form-group.has-errors') {
            border-color: $form-color-medium;
            color: $form-color-medium;
        }
    }

    &[readonly] {
        color: $form-color-medium;
    }

    &:disabled {
        background-color: $color-white;
        color: $form-color-medium;
        cursor: not-allowed;
    }

    &:focus {
        border-width: $form-element-border-width;
        border-color: $form-color-dark;
        outline: none;
        background-color: $form-color-light;
        color: $form-color-dark;

        @include when-inside('.form-group.has-errors') {
            border-color: $color-error-red;
        }
    }

    &.is-light {
        border-color: $form-color-light;

        &,
        &[readonly],
        &:disabled {
            @include when-inside('.form-group.has-errors') {
                border-color: $color-error-red;
            }
        }

        &:disabled {
            border-color: $color-dark-turquoise;
            background-color: $color-dark-turquoise;
            color: $form-color-special;
        }
    }
}

// input[type=text] elements
.text-input-element {
    position: relative;

    @include when-inside('.form-group.is-valid') {
        position: relative;

        &::after {
            display: flex;
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            border-right: px-to-rem(10px) solid $color-green;
            content: ' ';
        }

        .form-element {
            border-right-width: 0;
        }
    }
}

// select elements
.select-element {
    position: relative;

    &::after {
        @extend [class^='icon-'];
        @extend .icon-drop-down::before;
        display: flex;
        position: absolute;
        top: 0;
        right: px-to-rem(20px);
        align-items: center;
        width: px-to-rem(20px);
        height: 100%;
        font-size: px-to-rem(20px);
        line-height: 1;
        text-align: center;
        pointer-events: none;
    }

    .form-element {
        height: $form-element-height;
        padding-right: px-to-rem(50px);
        appearance: none;

        &:hover {
            cursor: pointer;
        }
    }

    select::-ms-expand {
        display: none;
    }

    @include when-inside('.form-group.is-valid') {
        &::after {
            right: 0;
            width: px-to-rem(40px);
            border-right: px-to-rem(10px) solid $color-green;
        }
    }

    option {
        @include font(form-element);
    }
}

// checkboxes & radio buttons
.checkbox-element,
.radiobutton-element {
    $element-size: 36px;
    display: flex;
    position: relative;
    align-items: center;
    min-height: $element-size;

    & + & {
        margin-top: px-to-rem(15px);
    }

    input,
    label::before {
        position: absolute;
        top: 50%;
        left: 0;
        width: $element-size;
        height: $element-size;
        margin: (-$element-size / 2) 0 0 0;
    }

    input {
        padding: 0;
        outline: 0;
        opacity: 0;
        appearance: none;
    }

    label {
        @include font(form-element);
        display: inline-block;
        position: relative;
        padding-left: $element-size + 10px;
        line-height: 1.3rem;

        @include when-inside('.form-group.has-errors') {
            /* stylelint-disable scss/selector-no-redundant-nesting-selector */
            &,
            & a {
                color: $color-error-red;
            }
            /* stylelint-enable scss/selector-no-redundant-nesting-selector */
        }

        &::before {
            display: flex;
            position: absolute;
            top: 50%;
            left: 0;
            align-items: center;
            width: ($element-size - 2 * $form-element-border-width);
            height: ($element-size - 2 * $form-element-border-width);
            margin: (($element-size - 2 * $form-element-border-width) / -2) 0 0 0;
            border-width: $form-element-border-width;
            border-style: solid;
            border-color: $color-form-border;
            background-color: $form-color-light;
            content: '';

            @include when-inside('.form-group.has-errors') {
                border-color: $color-error-red;
            }
        }

        > strong {
            display: block;
        }

        &:hover {
            cursor: pointer;
        }
    }

    [type='checkbox']:disabled + label,
    [type='radio']:disabled + label {
        color: $form-color-medium;

        &::before {
            border-color: $form-color-medium;

            @include when-inside('.form-group.has-errors') {
                border-color: $form-color-medium;
            }
        }
    }

    input:focus + label {
        &::before {
            border-color: $form-color-dark;

            @include when-inside('.form-group.has-errors') {
                border-color: $color-error-red;
            }
        }
    }

    &.is-light {
        label,
        input:focus + label,
        input:disabled + label,
        input:checked + label {
            &::before {
                border-color: $form-color-light;

                @include when-inside('.form-group.has-errors') {
                    border-color: $form-color-light;
                }
            }
        }

        input:disabled + label {
            &::before {
                border-color: rgba($form-color-light, $form-element-disabled-opacity);
                background-color: rgba($form-color-light, .8);

                @include when-inside('.form-group.has-errors') {
                    /* stylelint-disable scss/selector-no-redundant-nesting-selector */
                    &,
                    & a {
                        color: $form-color-medium;
                    }
                    /* stylelint-enable scss/selector-no-redundant-nesting-selector */
                }
            }
        }
    }

    &.is-inline {
        display: inline-flex;
        margin-right: px-to-rem(15px);

        &:last-of-type {
            margin-right: 0;
        }
    }
}

// checkboxes
.checkbox-element {
    input:checked + label {
        &::before {
            @extend [class^='icon-'];
            @extend .icon-checkmark::before;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            font-size: px-to-rem(24px);
        }
    }
}

// radio buttons
.radiobutton-element {
    input,
    label::before {
        border-radius: 50%;
        background-clip: padding-box;
    }

    input:checked + label {

        @include when-inside('.form-group.has-errors') {
            border-color: $color-error-red;
        }

        &::before {
            background-color: $form-color-dark;
            box-shadow: inset 0 0 0 8px $color-white;

            @include when-inside('.form-group.has-errors') {
                background-color: $color-error-red;
            }
        }
    }

    input:disabled:checked + label {
        &::before {
            background-color: $form-color-medium;

            @include when-inside('.form-group.has-errors') {
                background-color: $form-color-medium;
            }
        }
    }

    &.is-light {
        input:checked + label {
            &::before {
                border-width: 10px;
                background-color: $form-color-dark;
                box-shadow: none;

                @include when-inside('.form-group.has-errors') {
                    background-color: $color-error-red;
                }
            }
        }

        input:checked:disabled + label {
            &::before {
                background-color: $form-color-medium;

                @include when-inside('.form-group.has-errors') {
                    background-color: $form-color-medium;
                }
            }
        }
    }
}

.number-input-element {
    font-size: 0;

    .form-element {
        display: inline-block;
        width: calc(100% - 2 * #{$form-element-height});
        font-size: 1.5rem;
        text-align: center;
        vertical-align: top;
    }

    .button {
        width: $form-element-height;
        height: $form-element-height;
        padding: 0;
        outline: 0;

        &:not(:hover) {
            background-color: $form-color-medium;
        }

        [class^='icon-'],
        [class*=' icon-'] {
            margin: 0 auto;
            line-height: px-to-rem(35px);
        }

        .icon-magnifier-plus,
        .icon-magnifier-minus {
            margin: 0;
        }
    }
}

.input-group {
    display: table-row;
    position: relative;
    width: 100%;

    > .form-element {
        display: table-cell;
    }

    > .input-group-addon {
        display: table-cell;
        width: 1%;
        white-space: nowrap;
        vertical-align: middle;
    }
}

.form-group-hint {
    position: relative;
    text-align: right;

    .text {
        @include font(form-label, $form-color-dark);
        display: inline-block;
        width: 80%;
        margin-bottom: px-to-rem(10px);
    }
}

.hidden {
    display: none !important;
}
