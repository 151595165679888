/* stylelint-disable */
/// converts the given pixel value to a rem value
@function px-to-rem($px, $base: $base-font-size) {
    $rem: round($px / $base * 100) / 100;
    @return $rem * 1rem;
}

/// retrieve a predefined z-index value for the given key
@function z-index($key, $z-indizes: $z-indizes) {
    @return get-map-value($key, $z-indizes);
}

/// retrieve a predefined value from a map by key
@function get-map-value($key, $map: false) {
    @if not $map {
        @warn 'Function `get-map-value`: invalid map argument.';
    }
    @if not map-has-key($map, $key) {
        @warn 'Function `get-map-value`: no value found for `#{$key}` in given map `#{$map}`. Property omitted.';
    }
    @return map-get($map, $key);
}
/* stylelint-enable */
