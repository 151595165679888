.insurance-selector-policy-quantities {
    > section {
        margin-bottom: .5rem;
        border-bottom: 2px solid $color-semi-primary;

        @include media-breakpoint-up(sm) {
            margin-bottom: 1rem;
        }
    }

    .insurance-policy {
        position: relative;
        padding-bottom: .5rem;

        @include media-breakpoint-up(sm) {
            display: flex;
            align-items: center;
            margin-bottom: .5rem;
        }
    }

    .insurance-policy-stepper {
        .number-input-element {
            width: 160px;
        }
    }

    .insurance-policy-name {
        @include font(headline);
        padding-top: 1rem;
        line-height: .5rem;

        > span {
            @include font(form-label);
            color: $color-semi-primary;
        }

        @include media-breakpoint-up(sm) {
            width: 100%;
            padding: 0 60px 0 .5rem;
            line-height: 1rem;
        }
    }

    .insurance-policy-icon {
        position: absolute;
        top: 0;
        right: 0;
        width: 50px;
        font-size: 2.5rem;
        text-align: right;
    }

    .button-show-insurances {
        @include media-breakpoint-down(xs) {
            width: 100%;
        }
    }
}
