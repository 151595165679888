.api-step-flight {
    margin-top: px-to-rem(30px);

    + .api-step-flight {
        padding-top: px-to-rem(30px);
        border-top: 2px solid $color-semi-primary;
    }

    .bold {
        font-weight: bold;
    }

    .flight-option {
        @include font(copy);
        position: relative;
        margin-bottom: px-to-rem(20px);
        padding: px-to-rem(30px) px-to-rem(30px) px-to-rem(14px) px-to-rem(30px);
        background: $color-light-blue;

        @include media-breakpoint-down(xs) {
            padding: px-to-rem(10px);
        }

        &.is-selected {
            border: 1px solid $color-semi-primary;
            background-color: $color-white;

            .flight-detail {
                .bullet {
                    background-color: $color-white;
                }

                &.flight-detail-layover {
                    .bullet {
                        background-color: $color-transparent;
                    }
                }
            }
        }

        .badge {
            position: absolute;
            top: -7px;
            right: px-to-rem(20px);
            width: 114px;

            @include media-breakpoint-down(xs) {
                right: px-to-rem(10px);
            }

            > p {
                @include font(copy-bold-s, $color-white);
                position: relative;
                padding-top: 7px;
                line-height: 1.1;
                text-align: center;
                z-index: 1;
            }

            > svg {
                position: absolute;
                top: 0;
                width: 114px;
                height: 66px;
                transform: scaleX(1.3);
                z-index: 0;
                fill: $color-green;
            }
        }
    }

    .flight-options-error {
        @include font(copy-bold, $color-error-red);
        margin-bottom: px-to-rem(20px);
    }

    .info {
        display: flex;
        justify-content: space-between;

        @include media-breakpoint-down(lg) {
            flex-wrap: wrap;
            justify-content: normal;
        }

        @include media-breakpoint-down(xs) {
            display: block;
        }

        &.without-border {
            border: 0;
        }

        .flight-details {
            margin-left: px-to-rem(20px);
            padding-right: 10px;
        }
    }

    .airline-logo {
        align-self: center;
        width: 100px;
        height: auto;
        max-height: 40px;
    }

    .flight-class {
        width: 250px;
        text-align: right;

        @include media-breakpoint-down(lg) {
            width: 90%;
            margin-top: 26px;
            text-align: left;
        }

        @include media-breakpoint-down(xs) {
            width: 100%;
            margin-bottom: 10px;
            text-align: left;
        }

        > .button {
            padding: 0;
        }
    }

    .flight-class-intro {
        display: none;
        font-weight: bold;
        text-align: left;

        &.is-selected {
            display: inherit;
        }
    }

    .actions-wrapper {
        margin-top: px-to-rem(20px);
        border-top: 2px solid $color-semi-primary;

        .price-per-person {
            margin-top: px-to-rem(20px);
            margin-bottom: 0;
        }
    }

    .show {
        display: block;
    }

    .hidden {
        display: none;
    }

    .train-upgrade {
        margin-top: px-to-rem(20px);
        padding-top: px-to-rem(11px);
        border-top: 2px solid $color-semi-primary;
        text-align: left;

        label {
            strong {
                display: inline;
            }

            em {
                color: rgba($color-dark-blue, .8);
                font-style: inherit;
            }
        }

        .icon-info {
            @include font(icon-size);
            margin-left: 5px;
            line-height: inherit;
            cursor: pointer;
            vertical-align: middle;
        }
    }

    .train-upgrade-headline {
        @include font(copy);
        margin-bottom: .5em;
    }

    .hint {
        text-align: inherit;
    }

    .smaller-text {
        @include font(copy-s);
        padding: 10px 0 0 0;
    }

    .actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: px-to-rem(30px);

        @include media-breakpoint-down(xs) {
            // because Androide 4.4.2 don't know flex-wrap
            // flex-wrap: wrap;
            // justify-content: space-around;
            display: block;
            position: relative;
        }
    }

    .flight-price {
        @include font(headline-bold-l);
        flex: 1;
        padding-right: px-to-rem(20px);
        text-align: right;

        @include media-breakpoint-down(xs) {
            // because Androide 4.4.2 don't know flex-wrap
            // flex: none;
            // order: 1;
            width: 100%;
            padding-right: 0;
            padding-bottom: px-to-rem(20px);
        }

        &.is-selected {
            display: none;
        }
    }

    .flight-choice {
        min-width: 160px;
        text-align: right;
    }

    .flight-choice-text {
        padding-right: 5px;
        vertical-align: middle;
    }

    .selected-flight-wrapper {
        @include font(copy-s);
        margin-bottom: px-to-rem(20px);
        padding-bottom: px-to-rem(20px);
        text-align: right;
    }

    .selected-flight-price {
        text-align: right;

        .flight-price-info + span {
            @include font(headline-bold-l);
            margin-left: 5px;
        }
    }

    .flight-price-info {
        @include font(copy-bold);
    }

    .price-per-person {
        @include font(copy-s);
        margin-bottom: px-to-rem(20px);
        padding-bottom: 14px;
        line-height: 1;
        text-align: right;

        @include media-breakpoint-down(xs) {
            padding-bottom: 0;
        }
    }

    .flight-details-unavailable {
        display: flex;
        align-items: center;
        align-self: center;
        width: 100px;
        padding: 5px 0;
        border: .5px solid $color-semi-primary;
        background-color: $color-lighter-blue;
        font-size: px-to-rem(14px);
        line-height: 1.2;
        cursor: pointer;

        @include media-breakpoint-down(xs) {
            margin: 0 0 20px 0;
        }

        .icon {
            @include font(copy-s);
            margin: 0 5px;
        }
    }
}

.page-travel .api-step-transporttype .transport-option.is-selected .train-upgrade .radiobutton-element label:hover {
    cursor: pointer;
}

.page-travel .api-step-transporttype .transport-option.is-selected .train-upgrade .radiobutton-element.checked label:hover {
    cursor: default;
}

.more-options-choice {
    position: relative;
    margin-bottom: px-to-rem(20px);

    .button-down {
        position: relative;
        top: 2px;
    }

    .button-top {
        position: relative;
        top: 4px;
    }
}

.overlay-travel-flight {
    .bold {
        font-weight: bold;
    }

    .flight-segment {
        padding: 20px;
        background-color: $color-light-blue;

        + .flight-segment {
            margin-top: 20px;
        }

        > h2 {
            @include font(headline);
            margin-bottom: px-to-rem(20px);
        }
    }
}

.flight-details {
    flex: 1;
    margin-left: px-to-rem(10px);
    border-left: 2px dotted rgba($color-primary, .6);

    @include media-breakpoint-down(lg) {
        flex: none;
    }

    @include media-breakpoint-down(xs) {
        margin: px-to-rem(10px) 0;
    }

    .flight-detail {
        display: flex;
        position: relative;
        padding-left: px-to-rem(10px);

        $bullet-size: 8px;

        .bullet {
            position: absolute;
            top: 50%;
            left: -5px;
            width: $bullet-size;
            height: $bullet-size;
            background-color: $color-light-blue;

            &::after {
                display: block;
                position: absolute;
                top: $bullet-size / -2;
                left: 0;
                width: $bullet-size;
                height: $bullet-size;
                border-radius: 50%;
                background-color: $color-primary;
                content: '';
            }
        }

        .icon {
            align-self: center;
            margin: 0 px-to-rem(10px);
            font-size: px-to-rem(30px);
        }

        &.flight-detail-departure {
            .bullet {
                top: 0;
                height: 18px;

                &::after {
                    top: auto;
                    bottom: $bullet-size / -2;
                }
            }
        }

        &.flight-detail-layover {
            color: rgba($color-primary, .7);

            .icon {
                visibility: hidden;
            }

            .bullet {
                top: 50%;
                background-color: $color-transparent;

                &::after {
                    background-color: rgba($color-primary, .7);
                }
            }
        }

        &.flight-detail-arrival {
            .bullet {
                top: auto;
                bottom: 0;
                height: 14px;
            }
        }

        + .flight-detail {
            margin-top: px-to-rem(20px);
        }
    }
}
