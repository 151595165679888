.page-insurance {
    $default-margin: px-to-rem(30px);
    $default-margin-medium: px-to-rem(20px);
    $default-margin-small: px-to-rem(10px);

    .api-step-headline {
        margin-bottom: 0;
    }

    pre {
        color: $color-logo-red;
        font-style: italic;
    }

    .tax-hint {
        a {
            text-decoration: underline;
        }
    }

    .spacer-top {
        padding-top: 10px;
    }

    .logo-hansemerkur {
        max-width: 100%;
        margin: 0 10px;
        vertical-align: middle;
    }

    .api-step-region-noinsurance {
        p:last-child {
            margin-bottom: 0;
        }
    }

    // TODO these styles could/should be generally defined as number-input-element darkStyling
    .number-input-element .button {
        border: 2px solid $form-color-medium;
        background-color: $color-transparent;
        color: $form-color-medium;

        &.button-minus {
            border-right: 0;
        }

        &.button-plus {
            border-left: 0;
        }

        &:hover {
            border-width: 0;
            background-color: $button-color-dark;
            color: $color-white;
        }
    }

    .button-show-insurances {
        @include media-breakpoint-down(xs) {
            width: 100%;
        }
    }

    .insurance-selector-tabcontent {
        .radiobutton-element {
            &.is-light {
                display: inline-flex;
                margin-top: px-to-rem(7px);

                &:first-of-type {
                    margin-right: px-to-rem(20px);
                }

                input + label {
                    &::before {
                        border-style: solid;
                        border-color: $form-color-medium;
                    }
                }

                input:checked + label {
                    &::before {
                        border-width: 2px;
                        border-style: solid;
                        border-color: $color-form-border;
                        background-color: $form-color-dark;
                        box-shadow: inset 0 0 0 8px $color-white;

                        @include when-inside('.form-group.has-errors') {
                            background-color: $color-error-red;
                        }
                    }

                    @include when-inside('.form-group.has-errors') {
                        border-color: $color-error-red;
                    }
                }
            }
        }

        .insurance-blocked-alert {
            padding: 12px 16px 12px 16px;
            border: 2px $color-border-insurance-blocked solid;
            gap: 16px;
            width: 100%;
            display: flex;
            flex-direction: column;
            font-size: 18px;
            margin-bottom: 10px;

            .insurance-blocked-header {
                font-weight: 700;
                color: $color-alert-red;
            }
        }
    }
}
