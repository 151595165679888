.cabin-search {
    padding-top: 10px;
}

.search-error-space {
    padding-bottom: 50px;
}

.search-wrapper {
    display: flex;
    flex-direction: row;

    @include media-breakpoint-down(xs) {
        flex-direction: column;
        width: 100%;
    }

    > div {
        flex: 1 1 auto;
        width: 50%;

        @include media-breakpoint-down(xs) {
            width: 100%;
        }
    }

    .box {
        @include font(copy-s);
    }

    .cs-h {
        display: block;
        padding-bottom: 10px;
    }

    .button {
        padding: 0 2rem;
    }

    .cabin-search-input:disabled {
        border-color: $color-white;
        background-color: $color-white;
        color: $color-dark-blue;
        opacity: .5;
        pointer-events: none;
    }

    .search-status {
        @include font(copy-bold-s);
        display: flex;
        align-items: center;
        padding: 0 0 0 30px;

        @include media-breakpoint-down(xs) {
            height: 50px;
            margin: 0 0 25px 0;
            padding: 0 0 0 5px;
        }

        .running {
            display: flex;
            align-items: center;
            margin-left: -8px;

            .wrapper-loader {
                position: relative;
                width: 42px;
                height: 50px;

                .loader {
                    position: absolute;
                    top: 7px;
                    width: 36px;
                    height: 36px;
                    margin-top: 0;
                    border-width: 2px;
                }

                .icon-cabin-type {
                    position: absolute;
                    top: 7px;
                    left: 1px;
                    color: $color-dark-blue;
                    font-size: 36px;
                }
            }
        }

        .success {
            &::before {
                @extend [class^='icon-'];
                @extend .icon-checkmark::before;
                display: inline-block;
                width: 25px;
                height: 25px;
                margin-top: 2px;
                margin-right: 9px;
                padding: 2px 4px 0 3px;
                border-radius: 25px;
                background-color: $color-green;
                color: $color-white;
                font-size: 18px;
            }
        }

        .error {
            color: $color-unavailable;

            &::before {
                @extend [class^='icon-'];
                @extend .icon-ic-x::before;
                display: inline-block;
                width: 25px;
                height: 25px;
                margin-top: 2px;
                margin-right: 9px;
                padding: 5px 4px 0 6px;
                border-radius: 25px;
                background-color: $color-unavailable;
                color: $color-white;
                font-size: 14px;
                font-weight: bold;
            }
        }
    }
}

.cabin-view {
    .search-wrapper {
        padding: 15px;
        background-color: $color-light-blue;
    }
}
