.cabin-catergory {
    margin-top: 40px;

    @include media-breakpoint-down(sm) {
        margin-top: 30px;
    }

    .list-intro {
        @include font(copy-s);
        margin-top: -10px;
        padding-bottom: 20px;
    }

    .inner-cc {
        display: block;
        transition: opacity .25s ease-in-out;
        opacity: 0;

        &.show {
            height: auto;
            opacity: 1;
            visibility: visible;
        }

        &.hidden-anim {
            height: 0;
            visibility: hidden;
        }
    }

    .part-cc {
        margin-bottom: 20px;
        padding: 20px 15px 20px 20px;
        background-color: $color-light-blue;

        &:last-child {
            margin-bottom: 0;
        }

        &.selected {
            background-color: $color-white;
        }

        .category {
            position: relative;
            padding-right: 40px;
        }

        .image {
            > img {
                width: 100%;
            }
        }

        .notifications {
            display: flex;
            flex-direction: row;

            @include media-breakpoint-down(xs) {
                flex-direction: column;
                width: 100%;
            }

            .bold {
                @include font(copy-bold-s);
            }

            .deck,
            .special {
                flex: 1 1 auto;
                width: 50%;

                @include media-breakpoint-down(xs) {
                    width: 100%;
                }
            }
        }

        .cabin-price-cta-wrapper {
            padding: 30px 15px 0 8px;
            text-align: right;

            .cabin-selected {
                top: -5px;

                &::before {
                    padding: 2px 4px 0 0;
                }
            }
        }
    }

    .eq-list {
        position: relative;

        .eq-icon {
            position: absolute;
            top: 6px;
            width: 20px;
            height: 20px;
        }

        .text {
            display: list-item;
            margin-left: 25px;
        }
    }

    .deck-list {
        position: relative;
        padding-left: 25px;

        &::before {
            position: absolute;
            top: 13px;
            left: 0;
            width: 16px;
            height: 6px;
            background-color: $color-deck-mark;
            content: '';
        }
    }

    .cabin-number-deck {
        .part-cc {
            margin-top: 20px;
            padding: 20px 15px 20px 20px;
            border: 1px solid $color-dark-blue;
            background-color: $color-white;
        }

        .text {
            @include font(copy-s);
            display: inline;

            &.selected {
                padding: 0;
                @include font(copy-bold);
                color: $color-green;
            }

            &.underline {
                margin-left: 10px;
                text-decoration: underline;
                cursor: pointer;
            }
        }

        .cabin-number,
        .tuic-choise {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            @include media-breakpoint-down(xs) {
                flex-direction: column;
                align-items: normal;

                .cnd-available {
                    width: 100%;
                    margin-top: 20px;
                }
            }
        }

        .tuic-choise {
            @include media-breakpoint-down(xs) {
                flex-direction: column-reverse;
                padding-top: 20px;
            }
        }

        .benefit-hint {
            margin-bottom: 0;

            @include media-breakpoint-down(xs) {
                padding-top: 20px;
            }

            > a {
                padding-right: 1.5rem;
                @include media-breakpoint-down(sm) {
                    @media (orientation: portrait) {
                        text-align: right;
                    }
                }
            }

            > a::after {
                margin-top: -11px;
                font-size: 1.25rem;
            }
        }
    }

    .cabin-number > .cabin-price-cta-wrapper {
        padding: 0 0 0 0;

        .cabin-selected {
            top: 6px;
        }
    }

    .svg-quer-style {
        .background {
            fill: #bbe1f2;
            fill-rule: evenodd;
        }

        .cls-8 {
            fill: #1c93d9;
            fill-rule: evenodd;
        }

        .cls-3 {
            fill: #a5cdde;
        }

        .cls-4 {
            /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
            font-family: SourceSansPro;
            font-size: 6px;
            isolation: isolate;
            fill: #263e6a;
        }

        .cls-5 {
            letter-spacing: -.03em;
        }

        .st1 {
            fill: #bbe1f2;
            stroke: #a5cdde;
        }

        .st2 {
            /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
            font-family: SourceSansPro;
            font-size: 28px;
            isolation: isolate;
            fill: #263e6a;
        }
    }

    // Hintergrund weiß wird verändert sich der Umriss auf 1c93d9 -> (229|237|243) und die Linien auf a5cdde -> (201|210|217).
    .selected .svg-quer-style .background {
        /* stylelint-disable-next-line color-function-notation */
        fill: rgb(229, 237, 243);
    }

    .selected .svg-quer-style .st1 {
        /* stylelint-disable-next-line color-function-notation */
        fill: rgb(229, 237, 243);
        /* stylelint-disable-next-line color-function-notation */
        stroke: rgb(201, 210, 217);
    }

    .selected .svg-quer-style .cls-3 {
        /* stylelint-disable-next-line color-function-notation */
        fill: rgb(201, 210, 217);
    }
}
