.page-personal {
    .api-step-headline {
        margin-bottom: 0;
    }

    .ssl-hint {
        margin-top: 20px;
    }

    .react-region-cabin-tab-view {
        padding-top: 30px;
    }

    .parts > .booking-page-section:last-child {
        border: 0;
    }

    .api-step-invoice {
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 0;

        @include media-breakpoint-up(sm) {
            padding: 10px;
        }

        .booking-page-headline {
            margin-bottom: 1rem;
        }

        @include media-breakpoint-up(sm) {
            margin-bottom: 50px;
        }

        .invoice-inputs-section {
            margin-top: 36px;
            padding: 32px 10px 12px 10px;
            background-color: $color-white;

            @include media-breakpoint-up(sm) {
                margin-top: 36px;
                padding: 32px 30px 12px 30px;
            }
        }
    }

    .radiobutton-element {
        display: inline-flex;
        margin-top: px-to-rem(7px);

        &:first-of-type {
            margin-right: px-to-rem(20px);
        }
    }
}
