.action-link-description {
    @include font(copy);
    margin-bottom: 10px;
}

.action-link {
    @include font(headline);
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    text-decoration: none;
    white-space: nowrap;

    &[href^='mailto:'],
    &[href^='tel:'],
    &.action-link-email,
    &.action-link-phone {
        &::before {
            @extend [class^='icon-'];
            margin-right: 5px;
        }
    }

    &[href^='tel:'],
    &.action-link-phone {
        &::before {
            @extend .icon-telephone::before;
        }
    }

    &[href^='mailto:'],
    &.action-link-email {
        &::before {
            @extend .icon-email::before;
        }
    }
}

.action-link-details {
    @include font(form-label);
    margin-bottom: 20px;
}
