.page-footer-trust-bar {
    padding: px-to-rem(40px) 0 px-to-rem(10px) 0;
    background: $color-white;

    .trust-headline {
        margin-bottom: px-to-rem(20px);
    }

    .trust-item {
        margin-bottom: px-to-rem(30px);

        @include media-breakpoint-down(md) {
            text-align: center;
        }

        img {
            display: inline-block;
            width: 100%;
            max-width: 125px;
            height: auto;

            &.pay {
                max-width: 120px;
            }
        }

        h4 {
            @include font(headline-s);
            margin-top: px-to-rem(10px);
            margin-bottom: px-to-rem(10px);
        }

        p {
            @include font(copy-s);
            margin-right: px-to-rem(30px);

            @include media-breakpoint-down(md) {
                display: none;
            }
        }
    }

    .trustpilot-widget {
        margin-top: px-to-rem(20px);
        pointer-events: none;

        @include media-breakpoint-down(md) {
            margin-top: px-to-rem(33px);
        }

        > iframe {
            margin: 0 auto;
        }
    }
}

.page-footer-link-bar {
    padding: px-to-rem(60px) 0 px-to-rem(100px) 0;

    @include media-breakpoint-down(xs) {
        padding: px-to-rem(35px) 0 0 0;
    }

    .link-list {

        @include media-breakpoint-down(xs) {
            margin: 0 -10px;
            padding-bottom: 70px;
        }

        > li {
            display: inline-block;
            margin-right: px-to-rem(40px);

            @include media-breakpoint-down(xs) {
                display: flex;
                align-items: center;
                height: px-to-rem(80px);
                margin: 0;
                border-top: 1px solid $color-primary;
            }
        }

        a {
            @include font(copy);
            text-decoration: none;

            @include media-breakpoint-down(xs) {
                display: block;
                position: relative;
                width: 100%;
                padding: 0 px-to-rem(40px) 0 px-to-rem(10px);
            }

            &::after {
                @extend [class^='icon-'];
                @extend .icon-small-arrow-right::before;
                position: absolute;
                top: 5px;
                right: px-to-rem(10px);

                @include media-breakpoint-up(sm) {
                    display: none;
                }
            }
        }
    }

    .footer-hotline {
        @include font(copy);
        display: flex;
        align-items: center;
        height: px-to-rem(80px);
        margin: 0 -10px;
        border-top: 1px solid $color-primary;
        background-color: $color-light-blue;
        text-decoration: none;

        @include media-breakpoint-up(sm) {
            display: none;
        }

        .bold {
            @include font(copy-bold);
        }

        > a {
            position: relative;
            width: 100%;
            padding: 0 px-to-rem(40px) 0 px-to-rem(10px);
        }

        .icon {
            position: absolute;
            top: 1px;
            right: px-to-rem(10px);
            font-size: 1.4em;
        }
    }

    .logo-tui-cruises {

        @include media-breakpoint-down(xs) {
            width: 110px;
            height: auto;
            margin-bottom: px-to-rem(40px);
        }
    }
}
