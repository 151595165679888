.booking-pager {
    padding-top: px-to-rem(40px);

    &.top {
        margin-bottom: 20px;
        padding-top: 0;

        @include media-breakpoint-down(xs) {
            display: none;
        }
    }

    .right-column {
        text-align: right;
    }

    .left-column,
    .right-column {

        @include media-breakpoint-down(xs) {
            margin: 10px 0;
            text-align: center;
        }
    }

    .button-cta {
        @include media-breakpoint-down(xs) {
            width: 100%;
        }
    }

    // px-to-rem()
    .sticky-cta {
        position: fixed;
        right: 0;
        bottom: 0;
        left: 0;
        height: 70px;
        padding: 10px 0;
        transform: translate3d(0, 70px, 0);
        transform-style: preserve-3d;
        transition: transform 100ms ease-in-out;
        background-color: $color-sticky-cta-dark-blue;
        z-index: z-index(sticky-cta);
        backface-visibility: hidden;

        @include media-breakpoint-up(sm) {
            height: 100px;
            padding: 25px 0;
            transform: translate3d(0, 100px, 0);
        }

        &.show {
            transform: translate3d(0, 0, 0);
        }

        &.ne.show {
            transform: translate3d(0, 70px, 0);

            @include media-breakpoint-up(sm) {
                transform: translate3d(0, 100px, 0);
            }
        }

        .sticky-line {
            display: flex;
            align-items: stretch;
        }

        .text {
            display: none;
            flex-direction: column;
            justify-content: center;
            color: $color-white;

            @include media-breakpoint-up(sm) {
                display: inherit;
                line-height: 26px;
            }
        }

        .right-column {

            @include media-breakpoint-down(xs) {
                flex: 1;
                align-items: stretch;
                margin: 0;
            }
        }

        sup {
            font-size: smaller;
            line-height: .1;
            vertical-align: super;
        }
    }
}
