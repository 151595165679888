.error-page {
    .line {
        height: 2px;

        // padding-top: px-to-rem(20px);
        // padding-bottom: px-to-rem(10px);
        border-bottom: 2px solid $color-semi-primary;
    }

    .headline {
        @include font(headline-l);
        margin-bottom: px-to-rem(20px);
        text-align: center;
    }

    .text {
        @include font(copy);
        text-align: center;
    }

    .button-wrapper {
        padding-top: px-to-rem(30px);
        text-align: center;
    }
}
