.cabin-tab-content {
    padding: 30px 0 10px 0;

    .parts {
        display: none;
    }

    .show {
        display: block;
    }

    @include media-breakpoint-up(sm) {
        padding: 30px;
        border-right: 1px solid $color-border;
        border-bottom: 1px solid $color-border;
        border-left: 1px solid $color-border;
        box-shadow: 3px 3px 6px 0 $border-drop-shadow;
    }
}

.only-one-cabin {
    padding: 0 0 10px 0;
}

.personal-only-one-cabin {
    padding: 0;

    @include media-breakpoint-up(sm) {
        padding: 10px;
    }
}

.c-bottom-navigation {
    padding-top: 1px;
}
