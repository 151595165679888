/* stylelint-disable */
/// Apply a predefined font style
/// @param {String} $font A font name defined in the $fonts map variable

@mixin font($font, $color: false, $fonts: $fonts) {
    @if map-has-key($fonts, $font) {
        $font-definition: map-get($fonts, $font);
        @each $property, $value in $font-definition {
            #{$property}: $value;
        }
    } @else {
        @error 'Mixin `font`: No value could be retrieved for `#{$font}`. Please make sure it is defined in `$fonts` map.';
    }

    @if $color {
        color: $color;
    }
}

/// Make a context based selector a little more friendly
/// @param {String} $context A CSS selector
@mixin when-inside($context) {
    #{$context} & {
        @content;
    }
}

@mixin clearfix {
    &::after {
        display: table;
        clear: both;
        content: '';
    }
}

@mixin ellipsis($width: 100%) {
    display: inline-block;
    max-width: $width;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    word-wrap: normal;
}
/* stylelint-enable */
