.checklist {
    margin: 45px 0;

    .checklist-headline {
        margin: 20px 0 10px 0;
    }
}

.checkmark-list {
    > li {
        padding-left: 1.4em;

        &::before {
            @extend [class^='icon-'];
            @extend .icon-checkmark::before;
            display: inline-block;
            width: 1.4em;
            margin-left: -1.4em;
        }

        &.unchecked {
            opacity: .5;

            &::before {
                display: none;
            }
        }
    }

    &.inline {
        > li {
            display: inline-block;
        }
    }
}
