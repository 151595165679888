// change ttf also in index.html <link rel="preload href="" as="font" crossorigin="anonymous"/>

@font-face {
    /* stylelint-disable-next-line font-family-name-quotes */
    font-family: 'SourceSansPro';
    font-style: normal;
    font-weight: normal;
    src: url('../fonts/SansPro/SourceSansPro-Regular.ttf?canv3g') format('truetype');
    font-display: swap;
}

@font-face {
    /* stylelint-disable-next-line font-family-name-quotes */
    font-family: 'SourceSansPro-SemiBold';
    font-style: normal;
    font-weight: normal;
    src: url('../fonts/SansPro/SourceSansPro-SemiBold.ttf?canv3g') format('truetype');
    font-display: swap;
}
